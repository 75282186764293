import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import CustomHeading from "../components/Heading";
import CustomText from "../components/CustomText";
import Loader from "../components/Loader";
import removeTags from "../utils/removeTags"
import { Entypo, Ionicons, AntDesign } from '@expo/vector-icons';
import { Modal,Toast } from "native-base";
import TextInput from "../components/TextInput";
import * as actions from "../redux/actions/index"
import i18n from "i18n-js";
import { connect } from 'react-redux';
import CustomButton from "../components/CustomButton";

function Settings(props) {
    const [termModal, setTermModal] = useState(false)
    const [contactModal, setContactModal] = useState(false)
    const [terms, setTerms] = useState({})
    const [text, setText] = useState("")
    const [submit, setSubmit] = useState(false)

    const [loading, setLoading] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)


    useEffect(() => {
        props?.getTerms()
            .then((res) => {
                setTerms(res?.data?.page)
                setLoading(false)
            })
    }, [])

    function onSubmit() {
        setSubmit(true)
        if (text) {
            setBtnLoading(true)
            props.sendReport({
                user_id: props?.user?.user?.id,
                listing_id: 60,
                reason: text,
                text: text
            }).then(() => {
                setBtnLoading(false)
                setContactModal(false)
                Toast.show({ title: "Successfully submitted" })
            })
        }
    }

    if (loading) {
        return <Loader />
    }
    return (
        <View style={{ flex: 1 }}>
            <Header
                goBack={true}
                props={props}
                round={true}
                disableSearch={true}
                disableDrawer={true}
                disableNotifications={true} />
            <View style={{ paddingHorizontal: 20 }}>
                <CustomHeading
                    style={{ marginVertical: 20 }}
                    text={i18n.t('SETTINGS')}
                />
                <TouchableOpacity
                    onPress={() => setContactModal(true)}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', paddingHorizontal: 15, paddingVertical: 10, borderRadius: 40 }}>
                        <Entypo name="mail" size={19} color={"black"} />
                        <CustomText text={i18n.t('CONTACT_US')} style={{ marginLeft: 10 }} />
                        <View style={{ position: 'absolute', right: 20 }}>
                            <Ionicons name="chevron-forward-outline" size={15} color={"gray"} />
                        </View>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => setTermModal(true)}
                    style={{ marginTop: 10 }}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', paddingHorizontal: 15, paddingVertical: 10, borderRadius: 40 }}>
                        <Entypo name="text-document-inverted" size={19} color={"black"} />
                        <CustomText text={i18n.t('TERM_CON')} style={{ marginLeft: 10 }} />
                        <View style={{ position: 'absolute', right: 20 }}>
                            <Ionicons name="chevron-forward-outline" size={15} color={"gray"} />
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
            {termModal && (
                <Modal isOpen={termModal} onClose={() => setTermModal(false)} size={"xl"}>
                    <Modal.Content minH={"xl"} style={{ borderRadius: 25 }}>
                        <TouchableOpacity onPress={() => setTermModal(false)} style={{ position: 'absolute', top: 20, right: 15, zIndex: 2 }}>
                            <AntDesign name='close' size={25} color={"gray"} />
                        </TouchableOpacity>
                        <CustomHeading
                            style={{ margin: 20 }}
                            text={i18n.t('TERM_CON')}
                        />
                        <Modal.Body>
                            <CustomText text={i18n.locale == "en" ? removeTags(terms?.content) : removeTags(terms?.ar_content)} />
                        </Modal.Body>
                    </Modal.Content>
                </Modal>
            )}
            {contactModal && (
                <Modal isOpen={contactModal} onClose={() => setContactModal(false)} size={"xl"}>
                    <Modal.Content minH={"1/2"} style={{ borderRadius: 25 }}>
                        <TouchableOpacity onPress={() => setContactModal(false)} style={{ position: 'absolute', top: 20, right: 15, zIndex: 2 }}>
                            <AntDesign name='close' size={25} color={"gray"} />
                        </TouchableOpacity>
                        <CustomHeading
                            style={{ margin: 20, marginVertical: 15 }}
                            text={",,"}
                        />
                        <Modal.Body>
                            <CustomText text={i18n.t('CONTACT_DES')} />
                            <TextInput
                                value={text}
                                error={submit && !text}
                                onChangeText={(v) => setText(v)}
                                placeholder={i18n.t('TYPE_HERE')}
                                style={{ marginTop: 15, borderWidth: 0.75, borderColor: (submit && !text) ? 'red' : 'lightgray' }}
                                multiline={8}
                            />
                            <View style={{ marginTop: 20 }}>
                                <CustomButton
                                    text={i18n.t('SEND')}
                                    textColor="white"
                                    loading={loading}
                                    loaderColor="white"
                                    bgColor="black"
                                    onPress={onSubmit}
                                />
                            </View>
                        </Modal.Body>
                    </Modal.Content>
                </Modal>
            )}
        </View>
    )
}

function mapStateToProps({ user }) {
    return { user }
}
export default connect(mapStateToProps, actions)(Settings)
const styles = StyleSheet.create({})