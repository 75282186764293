import { StyleSheet, Text, View, FlatList, Image,TouchableOpacity } from 'react-native'
import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { connect } from 'react-redux'
import * as actions from "../redux/actions"
import Loader from '../components/Loader'
import { image_url } from "../myConfig/api.json"
import Notfound from '../components/Notfound'
import CustomText from '../components/CustomText'
import CustomHeading from '../components/Heading'
import i18n from 'i18n-js'

function SubCategories(props) {
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true)
        props.getSubSubCategories(props.route.params.id).then(() => setLoading(false))
    }, [])
    var marginEnglish = i18n.locale=="en" ? 10 : 0;

    // alert(props.route.params.carI`d)
    function renderItem({ item, index }) {
        return (
            <TouchableOpacity 
            onPress={()=>props.navigation.push('carListing',{carId:props.route.params.carId,catId:item.id,cat:props.route.params.cat,cat2:props.route.params.id})}
            key={item.id} style={{
                backgroundColor: 'white',
                padding: 10,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 1,
                },
                shadowOpacity: 0.20,
                shadowRadius: 1.41,
                elevation: 2,
                borderRadius: 15,
                marginTop: 12
            }}>
                <CustomText style={{ marginBottom: marginEnglish, textTransform: 'uppercase', marginLeft: marginEnglish,}} text={i18n.locale=="en"?item.title:item.ar_title}/>
                {/* text */}
                {/* <Text style={{ fontSize: 14, color: 'black', marginBottom: 10, textTransform: 'uppercase', marginLeft: 10, fontWeight: '700' }}>{item.title}</Text> */}
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{flex: 1,width: '30%' }}>
                        <Image
                            resizeMode="contain"
                            style={{padding: 10,width: '100%', height: 60 }}
                            source={item.image ? { uri: `${image_url}/sub-categories/${item.image}` } : require('../assets/placeholder.jpeg')}
                        />
                    </View>
                    <View style={{ width: '70%', paddingHorizontal: 5 }}>
                    <CustomText text={i18n.locale=="en"?item.description:item.ar_description} style={{color:'gray',fontSize: 12}}/>
                        {/* <Text style={{ fontSize: 10, color: 'gray' }}>{item.description}</Text> */}
                        {/* {item.description && item.description!='null'?<Text style={{ fontSize: 12,color:'gray' }}>{item.description}</Text>:null} */}
                    </View>
                </View>
            </TouchableOpacity>
        )
    }
    return (
        <View style={{flex:1}}>
            <Header
                goBack={true}
                props={props}
                disableSearch={true}
                disableDrawer={true}
                round={true}
                disableNotifications={true}
            />
            <View style={{width:'85%',alignSelf:'center',flex:1}}>
            <CustomHeading style={{marginTop:10,marginBottom: 10, textTransform: 'capitalize', marginLeft: 10}} text={i18n.locale=="en"?props.route.params.title:props.route.params.ar_title}/>
                {/* <Text style={[styles.titleBlack, { marginTop: 12, textTransform: 'capitalize' }]}>
                    {props.route.params.title}
                </Text> */}
                {loading ? (
                    <View style={{ height: 100, justifyContent: 'center', alignItems: 'center' }}>
                        <Loader />
                    </View>
                ) : (
                    <FlatList
                        data={props.subSubCategories}
                        renderItem={renderItem}
                        keyExtractor={(item) => item.id}
                        ListEmptyComponent={Notfound}
                    />
                )}
            </View>
        </View>
    )
}

function mapStateToProps({ subSubCategories }) {
    return { subSubCategories }
}
export default connect(mapStateToProps, actions)(SubCategories)
const styles = StyleSheet.create({
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
})
