
import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Animated,
  TouchableOpacity,
  FlatList
} from "react-native";
import Header from "../components/Header";
import {
  Entypo,
  AntDesign,
  FontAwesome,
  MaterialIcons
} from "@expo/vector-icons";
import ListingCard from "../components/ListingCard";
import { connect } from "react-redux";
import * as actions from "../redux/actions"
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import Loader from "../components/Loader"
import { image_url } from "../myConfig/api.json"
import CustomText from "../components/CustomText";
import CustomHeading from "../components/Heading";
import i18n from "i18n-js";
import { responsiveFontSize, responsiveHeight } from "react-native-responsive-dimensions";
import CustomButton from "../components/CustomButton";
import Slider from "./Slider";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

function Dashbaord(props) {
  async function registerForPushNotificationsAsync() {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      // console.log(token);
      // alert(token)
    } else {
      alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    return token;
  }

  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();
  const [listData, setListData] = useState([])


  const [currentItem, setCurrentItem] = useState(0);
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState({})

  useEffect(() => {
    setLoading(true)
    Promise.all([props.getUserCars(), props.getCategories(), props.getBlocks()])

    registerForPushNotificationsAsync().then(token => props.saveToken(token));

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      setNotification(notification);
    });

    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      // console.log(response);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, [])

  // useEffect(() => {
  //   if (props.categories.length > 0) {
  //     setActive(props.categories[0])
  //   }
  // }, [props.categories])
  useEffect(() => {
    const unsubscribe = props.navigation.addListener('focus', () => {
      setActive({})
    });

    return unsubscribe;
  }, [props.navigation])

  useEffect(() => {
    if (props.blocks.length > 0) {
      getAllBlocks(props.blocks)
    }
  }, [props.blocks])

  async function getAllBlocks(blocks) {
    const data = blocks.map(item => {
      return props.getFeature(item.title)
    })
    const finalData = await Promise.all(data)
    // console.log("arrr", finalData[0].data)
    const finalData2 = blocks.map((it, i) => {
      return {
        ...it,
        listing: finalData[i].data?.listings
      }
    })
    setListData(finalData2)
    setLoading(false)
  }


  useEffect(() => {
    if (active.title != "vendor") {

      if (active.id) {

        // setListLoading(true)
        props.navigation.push('service', { ...active, carId: currentItem.id })
        // props.getSubCategories(active.id).then(() => setListLoading(false))
      }
    } else {

      props.navigation.push('garageCategory', { carId: currentItem.id })
    }
  }, [active])

  const [items, setItems] = useState([
    {
      title: "Honda Accord",
      capacity: "284km",
      mileage: "4km",
      image: require("../assets/cars/taycan.png"),
    },
    {
      title: "Honda Civic",
      capacity: "180km",
      mileage: "10km",
      image: require("../assets/cars/taycan.png"),
    },
    {
      title: "Honda City",
      capacity: "220km",
      mileage: "12km",
      image: require("../assets/cars/taycan.png"),
    },
  ]);
  const getItemLayout = (data, index) => {
    return {
      length: Dimensions.get("screen").width,
      offset: Dimensions.get("screen").width * index,
      index,
    };
  };

  const onChangeItem = useCallback(({ viewableItems, changed }) => {
    if (viewableItems[0]?.item) {
      setCurrentItem(viewableItems[0]?.item);
    }
  }, [])


  if (loading) {
    return <Loader />
  }

  return (
    <View style={{ flex: 1, width: '100%', backgroundColor: 'black' }}>
      <Header navigation={props} />
      <ScrollView style={{ flexGrow: 1 }} bounces={false} showsVerticalScrollIndicator={false} overScrollMode="never">
        {/* TOP SECTION */}
        <View style={{ backgroundColor: "black", paddingBottom: 20 }}>
          {props?.user?.user?.type == "seller" ? (
            <>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20, paddingVertical: 10, alignItems: 'center' }}>
                <View>
                  <CustomHeading removeMargin={true} style={{ color: 'white' }} text={i18n.t('WELCOME')} />
                  <CustomText style={{ ...styles.whiteColor }} text={i18n.locale == "en" ? props?.user?.garage?.seller?.garage_name : props?.user?.garage?.seller?.ar_garage_name} />
                </View>
                <View>
                  <Image source={{ uri: `${image_url}/sellers/${props?.user.garage?.seller?.logo}` }} style={{ width: 80, height: 80 }} resizeMode="contain" />
                </View>
              </View>
              <View style={{ width: '70%', alignSelf: 'center' }}>
                <CustomButton
                  text={i18n.t('DASHBOARD')}
                  textColor="black"
                  loaderColor="black"
                  bgColor="white"
                  onPress={() => props.navigation?.push('garageDashboard')}
                  style={{ marginTop: 10 }}
                />
              </View>
            </>
          ) : (
            <>
              <FlatList
                horizontal={true}
                pagingEnabled={true}
                data={props.userCars}
                viewabilityConfig={{
                  itemVisiblePercentThreshold: 50,
                }}
                showsHorizontalScrollIndicator={true}
                onViewableItemsChanged={onChangeItem}
                getItemLayout={getItemLayout}
                renderItem={({ item, i }) => {
                  return (<TouchableOpacity
                    key={i}
                    disabled
                    // onPress={() => props.navigation.navigate("VehicleDetails", item)}
                    style={{
                      backgroundColor: "black",
                      flexDirection: "row",
                      width: Dimensions.get("screen").width,
                    }}
                  >
                    <View style={{ flexDirection: "column", flex: 1, padding: 12, paddingLeft: 30 }}>
                      <CustomHeading removeMargin={true} style={{ color: 'white' }} text={i18n.locale == "en" ? item.name : item?.ar_name} />
                      <View style={{ flexDirection: "column", marginTop: 12 }}>
                        <View style={{ flexDirection: "row" }}>
                          <Image source={require('../assets/Counter2.png')} style={{ width: 20, height: 20 }} resizeMode="contain" />
                          <CustomText style={{ ...styles.whiteColor, marginLeft: 6 }} text={item.registration_number} />
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 5 }}>
                          {/* <FontAwesome name="gears" size={18} color="white" /> */}
                          <Image source={require('../assets/Services2.png')} style={{ width: 20, height: 20 }} resizeMode="contain" />
                          <CustomText style={{ ...styles.whiteColor, marginLeft: 6 }} text={i18n.locale == "en" ? item.transmission?.title : item.transmission?.ar_title} />
                        </View>
                      </View>
                    </View>
                    <Image
                      // source={require("../assets/cars/car.png")}
                      source={{ uri: image_url + "/variants/" + item?.variant?.image }}
                      style={{
                        // flex: 1,
                        height: 140,
                        width: 140,
                        zIndex: 2,
                        marginRight: 10
                      }}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>)
                }}
              />
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                {props.userCars.map((item, i) => (
                  <View
                    key={item.id}
                    style={{
                      backgroundColor: item.id == currentItem.id ? "white" : "gray",
                      height: 10,
                      width: 10,
                      marginLeft: 6,
                      borderRadius: 5,
                    }}
                  />
                ))}
              </View>
            </>
          )}
        </View>

        {/* LISTINGS */}
        <View style={styles.body}>

          {!(props?.user?.user?.type == "seller") && (
            <>
              <CustomHeading style={{ marginTop: i18n.locale == "en" ? 15 : 25, paddingLeft: 30, paddingRight: 30, marginBottom: 5 }} text={i18n.t('DASH_TEXT1')} />
              <View style={{ padding: 2, flexDirection: "row" }}>
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                  {props.categories.map((item, index) => (
                    <TouchableOpacity
                      onPress={() => setActive(item)}
                      key={item.id} style={[styles.card, { marginLeft: index == 0 ? 30 : 10 }]}>
                      <Image resizeMode="contain" style={{ width: 50, height: 50 }} source={{ uri: `${image_url}/categories/${item.image}` }} />
                      <CustomText text={i18n.locale == "en" ? item.title : item.ar_title} style={{ ...styles.cardTitle, fontSize: 9 }} />
                    </TouchableOpacity>
                  ))}
                  <TouchableOpacity
                    onPress={() => setActive({ title: "vendor", id: "vendor" })}
                    style={[styles.card, { marginLeft: 10 }]}>
                    <Image resizeMode="contain" style={{ width: 50, height: 50 }} source={require('../assets/vendor.png')} />
                    <CustomText text={i18n.t('DASH_TEXT5')} style={{ ...styles.cardTitle, fontSize: 9 }} />
                  </TouchableOpacity>
                </ScrollView>
              </View>
            </>
          )}
          <CustomHeading style={{ marginTop: 5, paddingLeft: 30, paddingRight: 30, marginBottom: 5 }} text={i18n.t('OUR_LATEST')} />
          <Slider />
          {
            listData.map(it => {
              return (
                <>
                  <CustomHeading style={{ marginTop: 10, paddingLeft: 30, paddingRight: 30, marginBottom: 5 }} text={i18n.locale == "en" ? it.title : it.ar_title} />
                  <FlatList
                    data={it.listing}
                    showsHorizontalScrollIndicator={false}
                    horizontal={true}
                    inverted={i18n.locale=="en"?false:true}
                    renderItem={({ item, index }) => <ListingCard item={item} props={props} style={i18n.locale=="en"?{ marginLeft: index == 0 ? 30 : 10 }:{ marginRight: index == 0 ? 30 : 10 }} />}
                    keyExtractor={(item) => item.id}
                  />
                </>
              )
            })
          }
          {/* <CustomHeading style={{ marginTop: 5, paddingLeft: 30,marginBottom:5 }} text={i18n.t('DASH_TEXT3')} />
          <FlatList
            data={props.feature}
            showsHorizontalScrollIndicator={false}
            horizontal={true}
            renderItem={({ item, index }) => <ListingCard item={item} props={props} style={{ marginLeft: index == 0 ? 30 : 10 }} />}
            keyExtractor={(item) => item.id}
          />
          <CustomHeading style={{ marginTop: 5, paddingLeft: 30,marginBottom:5 }} text={i18n.t('DASH_TEXT4')} />
          <FlatList
            data={props.feature}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{marginBottom:20}}
            horizontal={true}
            renderItem={({ item, index }) => <ListingCard item={item} props={props} style={{ marginLeft: index == 0 ? 30 : 10 }} />}
            keyExtractor={(item) => item.id}
          /> */}
        </View>
      </ScrollView >
    </View >
  );
}

function mapStateToProps({ userCars, categories, subCategories, feature, blocks, user }) {
  return { userCars, categories, subCategories, feature, blocks, user }
}
export default connect(mapStateToProps, actions)(Dashbaord)


async function schedulePushNotification() {
  await Notifications.scheduleNotificationAsync({
    content: {
      title: "You've got mail! 📬",
      body: 'Here is the notification body',
      data: { data: 'goes here' },
    },
    trigger: { seconds: 2 },
  });
}

const styles = {
  title: {
    fontSize: 25,
    fontWeight: "bold",
    color: "white",
  },
  whiteColor: {
    color: "white",
  },
  titleBlack: {
    fontSize: 25,
    fontWeight: "bold",
    color: "black",
  },
  body: {
    backgroundColor: "#f0f0f0",
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
    // flex:1,
    minHeight: responsiveHeight(80),
    marginTop: -10,
    paddingBottom: 10
  },
  card: {
    flex: 1,
    height: 80,
    width: 95,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
  },
  cardTitle: {
    fontSize: 10,
    marginTop: 8,
    textAlign: "center"
  },
  cardBody: {
    fontColor: "gray",
  },
};
