import { StyleSheet, TouchableOpacity, View, Text, Linking, Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Select, CheckIcon, extendTheme, NativeBaseProvider, Modal, Button } from "native-base";
import CustomText from '../components/CustomText';
import sliceText from "../utils/sliceText"
import { connect } from 'react-redux';
import * as actions from "../redux/actions/index"
import { Feather } from "@expo/vector-icons";
import TextInput from "../components/TextInput";
import i18n from "i18n-js";
import CustomHeading from '../components/Heading';
import CustomButton from "../components/CustomButton";
import { image_url } from "../myConfig/api.json"

function MyProfile({ user, updateGarageProfile, refreshAuth }) {
    const seller = user?.garage?.seller
    const garage = user?.garage
    const [loading, setLoading] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [fields, setFields] = useState({
        name: "",
        phone: "",
        email: "",
        city: "",
        location: "",
        describtion: "",
        password: "",
        confirmPassword: "",
        image: {}
    })

    useEffect(() => {
        if (user) {
            setFields({
                name: seller?.garage_name,
                phone: garage?.phone,
                email: garage?.email,
                city: seller?.city,
                location: seller?.address,
                describtion: seller?.about,
                image: { ...fields.image, name: seller?.logo }
            })
        }
    }, [user])

    async function uploadFile() {
        var options = {
            allowMultiSelection: false,
            type: 'image/*'
        };
        const DocumentPicker = Platform.OS == "web" ? require('../DocumentPickerWeb') : require('react-native-document-picker')
        const response = await DocumentPicker.pick(options);
        setUploadedLogo(response[0]);
    }

    function onSubmit() {
        setSubmit(true)
        if (fields.password == fields.confirmPassword && fields.city && fields.phone && fields.location && fields.describtion) {
            const payload = new FormData()
            payload.append('user_id', garage?.id)
            payload.append('password', fields.password)
            payload.append('garage_name', fields.name)
            payload.append('phone', fields.phone)
            payload.append('about', fields.describtion)
            payload.append('city', fields.city)
            payload.append('address', fields.location)
            uploadedLogo && payload.append('image', uploadedLogo)

            setLoading(true)
            updateGarageProfile(payload).then(() => {
                refreshAuth().then(() => {
                    setLoading(false)
                    setSubmit(false)
                    setUploadedLogo(null)
                })
            })
                .catch(() => {
                    setLoading(false)
                })
        }
    }
    return (
        <View style={{ flex: 1 }}>
            <View style={{ width: '90%', alignSelf: 'center', flex: 1 }}>
                <CustomHeading text={i18n.t('PROFILE_DETAILS')} style={{ marginTop: 10, marginBottom: 10, textAlign: i18n.locale == "en" ? "left" : "right" }} />
                <TextInput
                    value={fields.name}
                    error={submit && !fields.name}
                    onChangeText={(v) => setFields({ ...fields, name: v })}
                    placeholder={i18n.t('GARAGE_NAME')}
                />
                <Select
                    selectedValue={fields.city}
                    onValueChange={(v) => setFields({ ...fields, city: v })}
                    accessibilityLabel={i18n.t('S3')}
                    placeholder={i18n.t('S14')}
                    textAlign={i18n.locale == "en" ? "left" : "right"}
                    style={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }}
                    fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
                    mt={3}
                    fontSize={14}
                    borderRadius={30}
                    backgroundColor="white"
                    borderColor={(submit && !fields.city) ? "red.500" : "white"}  // Set your desired border color here
                    borderWidth={1}
                    height={39.9}
                    shadow={Platform.OS == "web" ? "none" : "1"}
                    _selectedItem={{
                        bg: "teal.600",
                        endIcon: <CheckIcon size={5}
                        />
                    }}>
                    <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY1')} label={i18n.t('CITY1')} value={"Abu Dhabi"} />
                    <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY2')} label={i18n.t('CITY2')} value={"Al Ain"} />
                    <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY3')} label={i18n.t('CITY3')} value={"Ajman"} />
                    <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY4')} label={i18n.t('CITY4')} value={"Dubai"} />
                    <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY5')} label={i18n.t('CITY5')} value={"Fujairah"} />
                    <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY6')} label={i18n.t('CITY6')} value={"Sharjah"} />
                    <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY7')} label={i18n.t('CITY7')} value={"Ras Al Khaimah"} />

                </Select>
                <TextInput
                    value={fields.location}
                    error={submit && !fields.location}
                    onChangeText={(v) => setFields({ ...fields, location: v })}
                    placeholder={i18n.t('LOCATION_LINK')}
                    style={{ marginTop: 15 }}
                />
                <TextInput
                    value={fields.phone}
                    error={submit && !fields.phone}
                    onChangeText={(v) => setFields({ ...fields, phone: v })}
                    placeholder={i18n.t('SIGN_TEXT5')}
                    style={{ marginTop: 15 }}
                />


                <TextInput
                    value={fields.describtion}
                    error={submit && !fields.describtion}
                    onChangeText={(v) => setFields({ ...fields, describtion: v })}
                    placeholder={i18n.t('ABOUT_GARAGE')}
                    style={{ marginTop: 15 }}
                    multiline={8}
                />
                <TextInput
                    value={fields.email}
                    error={submit && !fields.email}
                    placeholder={i18n.t('LOGIN_TEXT2')}
                    onChangeText={(v) => setFields({ ...fields, email: v })}
                    style={{ marginTop: 15 }}
                    disable={true}
                />
                <TextInput
                    value={fields.password}
                    // error={submit && !fields.password}
                    placeholder={i18n.t('LOGIN_TEXT3')}
                    onChangeText={(v) => setFields({ ...fields, password: v })}
                    secureTextEntry={true}
                    style={{ marginTop: 15 }}
                />
                <TextInput
                    value={fields.confirmPassword}
                    // error={submit && !fields.confirmPassword}
                    placeholder={i18n.t('SIGN_TEXT1')}
                    onChangeText={(v) => setFields({ ...fields, confirmPassword: v })}
                    secureTextEntry={true}
                    style={{ marginVertical: 15 }}
                />
                <View style={{ backgroundColor: 'white', borderRadius: 10, padding: 20, marginBottom: 20, flexDirection: 'row', alignItems: 'center' }}>
                    <View>
                        <CustomText text={i18n.t('LOGO') + " " + (uploadedLogo ? uploadedLogo?.name : sliceText(fields.image?.name, 20))} style={{ color: 'gray' }} />
                    </View>
                    <TouchableOpacity
                        onPress={() => uploadFile()}
                        style={{ paddingLeft: 20 }}>
                        <Feather name="edit" style={{ color: 'gray' }} size={15} />
                    </TouchableOpacity>
                    {
                        !uploadedLogo && (
                            <TouchableOpacity
                                onPress={() => {
                                    Linking.openURL(`${image_url}/sellers/${seller?.logo}`)
                                }}
                                style={{ paddingLeft: 20 }}>
                                <Feather name="eye" style={{ color: 'gray' }} size={15} />
                            </TouchableOpacity>
                        )
                    }
                </View>
                {fields.password == fields.confirmPassword ? null : <Text style={{ textAlign: 'center', width: '100%', color: 'red', marginVertical: 5, fontSize: 12 }}>{"Password does not match"}</Text>}
                <View>
                    <CustomButton
                        text={i18n.t('UPDATE')}
                        textColor="white"
                        loading={loading}
                        loaderColor="white"
                        bgColor="black"
                        onPress={onSubmit}
                    />
                </View>
            </View>
        </View>
    )
}

function mapStateToProps({ user }) {
    return { user }
}

export default connect(mapStateToProps, actions)(MyProfile)
const styles = StyleSheet.create({})