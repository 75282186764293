import React from "react";
import { Text, View, TouchableOpacity } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { connect } from "react-redux";
import * as actions from "../redux/actions"
import CustomText from "./CustomText";

import i18n from "i18n-js";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Updates from 'expo-updates';


function CustomDrawer(props) {

  function navigateUserToProfile() {
    var userType = props.user?.user?.type;
    if (userType == "seller") {
      props.props.navigation.navigate('garageDashboard')
    }
    else {
      props.props.navigation.navigate('profile')
    }
  }


  // console.log(props.props.navigation);
  // console.log("drawer custom");
  return (
    <View style={{ flex: 1, padding: 12 }}>
      <TouchableOpacity
        onPress={() => navigateUserToProfile()}
        style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ width: 50 }}>
          <FontAwesome name="user-circle" size={30} color="black" />
        </View>
        <View>
          <CustomText text={props.user?.user?.type == "seller" ? i18n.t('DASHBOARD') : props.user?.user?.name} />
        </View>
      </TouchableOpacity>

      <View style={styles.lineBreak} />

      {/* <TouchableOpacity
        style={{ flexDirection: "row", alignItems: "center" }}
        onPress={() => props.props.navigation.navigate("Search")}
      >
        <EvilIcons name="search" size={30} color="black" />
        <Text style={styles.username}>Search</Text>
      </TouchableOpacity> */}
      <TouchableOpacity
        style={{ flexDirection: "row", alignItems: "center" }}
        onPress={async () => {
          const language = await AsyncStorage.getItem('language')
          if (language) {
            AsyncStorage.setItem('language', language == 'en' ? 'ar' : 'en').then(() => {
              Updates.reloadAsync()
            })
          } else {
            AsyncStorage.setItem('language', 'ar').then(() => {
              Updates.reloadAsync()
            })
          }
        }}
      >
        <View style={{ width: 50 }}>
          <FontAwesome name="language" size={30} color="black" />
        </View>
        <View>
          <CustomText text={i18n.t('S17')} />
        </View>
      </TouchableOpacity>
      {/* <TouchableOpacity
        style={{ flexDirection: "row", alignItems: "center", marginTop: 12 }}
        onPress={() => props.props.navigation.navigate("notfications")}
      >
        <View style={{width:50}}>
        <Ionicons name="notifications" size={30} color="black" />
        </View>
        <CustomText text={i18n.t('DRAWER_TEXT2')}/>
      </TouchableOpacity> */}

      {props.user?.user?.type == "seller" ? (
        <>
          <TouchableOpacity
            style={{ flexDirection: "row", alignItems: "center", marginTop: 12 }}
            onPress={async () => {
              props.props.navigation.navigate('settings')
            }}
          >
            <View style={{ width: 50 }}>
              <AntDesign name="setting" size={30} color="black" />
            </View>
            <View>
              <CustomText text={i18n.t('SETTINGS')} />
            </View>
          </TouchableOpacity>
        </>

      ) : (<></>)}


      <TouchableOpacity
        style={{ flexDirection: "row", alignItems: "center", marginTop: 12 }}
        onPress={() => {
          props.saveToken("")
          props.logOut()
        }}
      >
        <View style={{ width: 50 }}>
          <AntDesign name="logout" size={24} color="black" />
        </View>
        <View>
          <CustomText text={i18n.t('DRAWER_TEXT3')} />
        </View>
      </TouchableOpacity>
    </View>
  );
}

function mapStateToProps({ user }) {
  return { user }
}

export default connect(mapStateToProps, actions)(CustomDrawer)

const styles = {
  username: {
    fontSize: 20,
    marginLeft: 12,
  },
  lineBreak: {
    height: 1,
    backgroundColor: "#c0c0c0",
    // margin: 24,
    marginVertical: 10
  },
};
