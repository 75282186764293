import React from "react";
import { Text, View, TouchableOpacity, Image, ActivityIndicator } from "react-native";
import i18n from 'i18n-js';

export default function CustomButton(props) {
  const { text, onPress, img, textColor, bgColor, addShadow, addFlex,loading,loaderColor,style } = props;
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        {
          height: 40,
          ...style
        },
        addFlex ? { flex: 1 } : {},
      ]}
    >
      <View
        style={[
          {
            flexDirection: "row",
            backgroundColor: bgColor,
            // padding: 16,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 20,
            flex: 1,
          },
          addShadow ? styles.boxWithShadow : {},
        ]}
      >
        {loading ? (
          <ActivityIndicator size={30} color={loaderColor?loaderColor:"black"}/>
        ) : (
          <>
            {img ?(
              <Image
                source={img}
                style={{ height: 25, width: 25 }}
                resizeMode="contain"
              />
            ):<Text style={{ color: textColor,fontFamily:i18n.locale=="en"?'Poppins-Medium':'GE-SS-Unique-Bold' ,width:'100%',textAlign:'center' }}>{text}</Text>}
          </>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = {
  boxWithShadow: {
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
  },
};
