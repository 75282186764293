import { StyleSheet, Text, View } from 'react-native'
import i18n from 'i18n-js';
import { Localization } from 'expo-localization';
import React from 'react'

export default function CustomText({text,shouldDo=false,style={},bold}) {
  if(shouldDo){
    return (
        <Text style={{...styles.text,fontFamily:i18n.locale=="en"?(bold?'Poppins-Medium':'Poppins-Regular'):'Poppins-Regular',textAlign:i18n.locale=="en"?"left":"left",...style}}>{text}</Text>
    )
  }
  else{
    return (
        <Text style={{...styles.text,fontFamily:i18n.locale=="en"?(bold?'Poppins-Medium':'Poppins-Regular'):'GE-SS-Unique-Light',textAlign:i18n.locale=="en"?"left":"right",...style}}>{text}</Text>
    )
  }
}

const styles = StyleSheet.create({
    text:{
        fontSize:14,
        textAlign:'left',
        includeFontPadding:false,
        width:'100%'
    }
})