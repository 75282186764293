import {GET_MODELS } from "../actions/types";

const initialState=[]

export default function models(state = initialState, action) {
    switch (action.type) {
      case GET_MODELS:
        return action.payload;
      default:
        return state;
    }
  }