import {  GET_VENDOR_LISTING } from "../actions/types";

const initialState={}
export default function vendorListing(state = initialState, action) {
    switch (action.type) {
      case GET_VENDOR_LISTING:
        return action.payload;
      default:
        return state;
    }
  }