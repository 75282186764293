import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, FlatList, StyleSheet } from "react-native";
import Header from "../components/Header";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import Loader from "../components/Loader"
import Notfound from "../components/Notfound";
import { TouchableOpacity } from "react-native-gesture-handler";
import { AntDesign } from '@expo/vector-icons'; 
import NotiModal from "../components/NotiModal"
import CustomText from "../components/CustomText";
import i18n from "i18n-js";

function CreateVehicle(props) {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [current, setCurrent] = useState({})


    useEffect(() => {
        setLoading(true)
        props.getNotifications().then(() => setLoading(false))
    }, [])



    function renderItem({ item }) {
        return (
            <TouchableOpacity 
            onPress={()=>{
                setCurrent(item)
                setModal(true)
            }}
            style={{ padding: 10, width: '95%', alignSelf: 'center', marginTop: 10, backgroundColor: 'white', borderRadius: 10,flexDirection:'row',alignItems:'center',justifyContent:'center' }}>
                <View style={{width:'20%',justifyContent:'center',alignItems:'center'}}>
                <AntDesign name="notification" size={30} color="gray" />
                </View>
                <View style={{width:'80%'}}>
                    <CustomText text={i18n.locale=="en"?item.title:item.ar_title} style={{fontSize:14}}/>
                    <CustomText text={item.description?.length>40?(item.description.slice(37)+"..."):(i18n.locale=="en"?item.description:item.ar_description)} style={{fontSize:12,color:'gray'}}/>
{/* 
                    <Text style={{fontSize:14}}>{item.title}</Text>
                    <Text style={{fontSize:12,color:"gray"}}>{item.description?.length>40?(item.description.slice(37)+"..."):item.description}</Text> */}
                </View>
            </TouchableOpacity>
        )
    }

    if (loading) {
        return <Loader />
    }
    return (
        <View style={{ flex: 1 }}>
            <Header
                goBack={true}
                round={true}
                props={props}
                disableSearch={true}
                disableDrawer={true}
                disableNotifications={true}

            />
            <NotiModal
            visible={modal}
            title={current.title}
            des={current.description}
            closeModle={()=>setModal(false)}
            />
            {
                !loading ? (
                    <FlatList
                        data={props.notifications?.data}
                        contentContainerStyle={{ width:'85%',alignSelf:'center'}}
                        renderItem={renderItem}
                        keyExtractor={(item) => item.id.toString()}
                        ListEmptyComponent={Notfound}
                    />
                ) : <Loader />
            }
        </View>
    );
}

function mapStateToProps({ notifications }) {
    return { notifications }
}

export default connect(mapStateToProps, actions)(CreateVehicle)

const styles = StyleSheet.create({

})
