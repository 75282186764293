import { StyleSheet, Image, View, FlatList, Dimensions, Platform } from 'react-native'
import React, { useEffect, useRef, useState } from 'react'
import * as actions from "../redux/actions/index"
import { connect } from 'react-redux'
import { image_url } from "../myConfig/api.json"
import i18n from "i18n-js";

const { width } = Dimensions.get('window');

function Slider({ getBanners }) {
    const [index, setIndex] = useState(0)
    const sliderRef = useRef(null)
    const [banners, setBanners] = useState([])

    useEffect(() => {
        getBanners()
            .then(res => {
                setBanners(res?.data?.banners)
            })
    }, [])

    const handleScroll = (event) => {
        const offsetX = event.nativeEvent.contentOffset.x
        const currentIndex = Math.round(offsetX / width)
        setIndex(currentIndex)
    }

    function renderItem({ item }) {
        return (
            <View style={styles.itemCon}>
                <Image
                    style={styles.img}
                    source={{ uri: `${image_url}/banners/${item?.name}` }}
                />
            </View>
        )
    }

    return (
        <View style={[
            styles.sliderCon,
            Platform.OS != "web" && {
                justifyContent: 'center',
                alignItems: 'center'
            }
        ]}>
            <FlatList
                data={banners}
                ref={sliderRef}
                renderItem={renderItem}
                horizontal={true}
                inverted={i18n.locale=="en"?false:true}
                showsHorizontalScrollIndicator={false}
                pagingEnabled={true}
                keyExtractor={(item, index) => index.toString()}
                onScroll={handleScroll}
                scrollEventThrottle={16}
            />
            <View style={[
                styles.dotCon,
                Platform.OS == "web" && {
                    alignSelf: 'center'
                }
            ]}>
                {banners.map((_, i) => (
                    <View
                        key={i}
                        style={[
                            styles.dot,
                            {
                                backgroundColor: i === index ? "#000" : "lightgray"
                            }
                        ]}
                    />
                ))}
            </View>
        </View>
    )
}

export default connect(null, actions)(Slider)

const styles = StyleSheet.create({
    sliderCon: {
        width: '100%',
        marginTop: 10,
        height: 200,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    img: {
        width: width -60, // 90% of the screen width
        height: 190,
        borderRadius: 20,
        backgroundColor: "gray",
        resizeMode: 'cover',
    },
    dotCon: {
        position: 'absolute',
        flexDirection: 'row',
        bottom: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dot: {
        width: Platform.OS == "web" ? 10 : 12,
        height: Platform.OS == "web" ? 10 : 12,
        borderRadius: 6,
        marginHorizontal: 5,
        backgroundColor: 'lightgray'
    },
    itemCon: {
        width: width,
        justifyContent: 'center',
        alignItems: 'center',
    }
})
