import { StyleSheet, Image, View, ScrollView } from 'react-native'
import React, { useState } from 'react'
import Header from '../components/Header'
import { connect } from 'react-redux'
import CustomButton from '../components/CustomButton'
import CustomHeading from '../components/Heading'
import CustomText from '../components/CustomText'
import i18n from "i18n-js";
import { TouchableOpacity } from 'react-native-gesture-handler'
import { responsiveHeight, responsiveWidth } from 'react-native-responsive-dimensions'
import CreateListing from './CreateListing'
import MyListing from "./MyListing"
import MyProfile from "./MyProfile"
import { image_url } from "../myConfig/api.json"

function GarageDashboard(props) {
    const [currentItem, setCurrent] = useState("myListings")


    function renderContent() {
        if (currentItem == "myListings") {
            return <MyListing />
        }
        else if (currentItem == "addListings") {
            return <CreateListing setCurrent={setCurrent} />
        }
        else if (currentItem == "profile") {
            return <MyProfile />
        }
    }
    return (
        <View style={{ flex: 1 }}>
            <Header disableSearch={true} navigation={props} />
            <ScrollView style={{ flexGrow: 1 }} bounces={false} showsVerticalScrollIndicator={false} overScrollMode="never">
                <View style={{ backgroundColor: "black", paddingBottom: 40, alignItems: 'center' }}>
                    <View style={{ backgroundColor: 'white', width: '90%', borderRadius: 10 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20, paddingVertical: 10, alignItems: 'center' }}>
                            <View>
                                <CustomHeading removeMargin={true} style={{ color: 'black' }} text={i18n.t('WELCOME')} />
                                <CustomText style={{ color: 'black' }} text={i18n.locale == "en" ? props?.user?.garage?.seller?.garage_name : props?.user?.garage?.ar_garage_name} />
                            </View>
                            <View>
                                <Image source={{ uri: `${image_url}/sellers/${props?.user.garage?.seller?.logo}` }} style={{ width: 100, height: 100 }} resizeMode="contain" />
                            </View>
                        </View>
                        <View style={{ width: '100%', alignSelf: 'center', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', paddingBottom: 10, paddingTop: 5 }}>
                            <View style={{ width: '30%' }}>
                                <TouchableOpacity onPress={() => setCurrent("myListings")}>
                                    <View style={{ backgroundColor: currentItem == "myListings" ? "#B9C7DB" : "white", borderWidth: 1, borderColor: 'lightgray', borderRadius: 20, justifyContent: 'center', alignItems: 'center', paddingVertical: 8, }}>
                                        <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('MY_LISTING')} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                            <View style={{ width: '30%' }}>
                                <TouchableOpacity onPress={() => setCurrent("addListings")}>
                                    <View style={{ backgroundColor: currentItem == "addListings" ? "#B9C7DB" : "white", borderWidth: 1, borderColor: 'lightgray', borderRadius: 20, justifyContent: 'center', alignItems: 'center', paddingVertical: 8, }}>
                                        <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('ADD_LISTING')} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                            <View style={{width:'30%'}}>
                                <TouchableOpacity onPress={() => setCurrent("profile")}>
                                    <View style={{ backgroundColor: currentItem == "profile" ? "#B9C7DB" : "white", borderWidth: 1, borderColor: 'lightgray', borderRadius: 20, justifyContent: 'center', alignItems: 'center', paddingVertical: 8, }}>
                                        <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('PROFILE')} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    <View style={{ width: '90%', alignSelf: 'center', marginTop: 10 }}>
                        <View style={{ width: '100%', alignSelf: 'center', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingBottom: 10, paddingTop: 5 }}>
                            <View style={{ backgroundColor: 'white', borderRadius: 10, justifyContent: 'center', alignItems: 'center', paddingVertical: 5, width: '30%' }}>
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('APPROVED')} />
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('LISTING')} />
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={props?.garageListing?.listings?.data?.filter(it => it.status == "approved")?.length} />
                            </View>
                            <View style={{ backgroundColor: 'white', borderRadius: 10, justifyContent: 'center', alignItems: 'center', paddingVertical: 5, width: '30%' }}>
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('PENDING')} />
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('LISTING')} />
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={props?.garageListing?.listings?.data?.filter(it => it.status == "pending")?.length} />
                            </View>
                            <View style={{ backgroundColor: 'white', borderRadius: 10, justifyContent: 'center', alignItems: 'center', paddingVertical: 5, width: '30%' }}>
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('TOTAL')} />
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={i18n.t('LISTING')} />
                                <CustomText style={{ ...styles.whiteColor, marginLeft: 6, textAlign: 'center' }} text={props?.garageListing?.listings?.data?.length} />
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.body}>
                    {renderContent()}
                </View>
            </ScrollView>
        </View>
    )
}

function mapStateToProps({ user, garageListing }) {
    return { user, garageListing }
}

export default connect(mapStateToProps)(GarageDashboard)

const styles = StyleSheet.create({
    whiteColor: {
        fontSize: 13,
        color: 'black'
    },
    body: {
        backgroundColor: "#f0f0f0",
        borderTopRightRadius: 40,
        borderTopLeftRadius: 40,
        // flex:1,
        minHeight: responsiveHeight(80),
        marginTop: -33,
        paddingBottom: 10
    },
})