import { GET_FEATURES } from "../actions/types";

const initialState=[]
export default function feature(state = initialState, action) {
    switch (action.type) {
      case GET_FEATURES:
        return action.payload;
      default:
        return state;
    }
  }