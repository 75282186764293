export const LOGIN="LOGIN"
export const GET_USER_CARS="GET_USER_CARS"
export const GET_CARS="GET_CARS"
export const REG_CAR="REG_CAR"
export const GET_CAT="GET_CAT"
export const GET_SUB_CAT="GET_SUB_CAT"
export const GET_SUB_SUB_CAT="GET_SUB_SUB_CAT"
export const GET_FEATURES="GET_FEATURE"
export const GET_BLOCK="GET_BLOCK"
export const GET_VENDORS="GET_VENDORS"
export const GET_VENDOR_DETAILS="GET_VENDOR_DETAILS"
export const SEARCH_LIST="SEARCH_LIST"
export const CAR_LISTING="CAR_LISTING"
export const GET_SEARCH_HISTORY="GET_SEARCH_HISTORY"
export const GET_NOTIFICATIONS="GET_NOTIFICATIONS"
export const GET_VENDOR_LISTING="GET_VENDOR_LISTING"
export const GET_LISTING_DETAIL="GET_LISTING_DETAIL"
export const GET_REVIEWS="GET_REVIEWS"
export const GET_TYPES="GET_TYPES"
export const GET_MODELS="GET_MODELS"
export const GET_TRANSMISSION="GET_TRANSMISSION"
export const GET_YEARS="GET_YEARS"
export const GET_GARAGE_LISTING="GET_GARAGE_LISTING"



















