import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, ScrollView, Dimensions,TouchableOpacity } from "react-native";
import CustomButton from "../components/CustomButton";
import { Ionicons } from "@expo/vector-icons";

import TextInput from "../components/TextInput";
import * as actions from "../redux/actions";
import CustomText from "../components/CustomText";
import { connect } from "react-redux";
import i18n from 'i18n-js';
import CustomHeading from "../components/Heading";
import { Toast } from "native-base";

const { height } = Dimensions.get('window')
function Login(props) {
    const checkAndRedirect = () => props.navigation.navigate("Dashboard");
    const [loading, setLoading] = useState(false)
    const [steps,setSteps]=useState(0)
    const [opt,setOtp]=useState("")
    const [fields, setFields] = useState({ email: "", password: "",confirm_password:"" })
    const [submit, setSubmit] = useState(false)



    function onSendOTP() {
        setSubmit(true)
        if (fields.email) {
            setLoading(true)
            props.sendOTP({email:fields.email}).then((res) => {
                setLoading(false)
                setSubmit(false)
                setSteps(1)
                Toast.show({tintColor:'green.100',title:res.data?.message})
            }).catch(err=>{
                setLoading(false)
                Toast.show({tintColor:'red.100',title:err?.response?.data?.message})
            })
        }
    }

    function onVerifyOTP() {
        setSubmit(true)
        if (opt) {
            setLoading(true)
            props.verifyOTP({email:fields.email,otp:opt}).then((res) => {
                setLoading(false)
                setSubmit(false)
                setOtp("")
                setFields({ ...fields,email: "" })
                setSteps(2)
                Toast.show({tintColor:'green.100',title:res.data?.message})
            }).catch(err=>{
                setLoading(false)
                Toast.show({tintColor:'red.100',title:err?.response?.data?.message})
            })
        }
    }

    function onSubmit() {
        setSubmit(true)
        if (fields.email && fields.password) {
            setLoading(true)
            props.forgetPassword(fields).then((res) => {
                setLoading(false)
                setSubmit(false)
                setFields({ email: "", password: "",confirm_password:"" })
                setSteps(0)
                Toast.show({tintColor:'green.100',title:res.data?.message})
            }).catch(err=>{
                setLoading(false)
                Toast.show({tintColor:'red.100',title:err?.response?.data?.message})
            })
        }
    }

    function renderStep(){
        if(steps==0){
            return(
                <>
                <TextInput
                    value={fields.email}
                    error={submit && !fields.email}
                    onChangeText={(v) => setFields({ ...fields, email: v })}
                    placeholder={i18n.t('LOGIN_TEXT2')}
                />
                <CustomButton
                    text={i18n.t('G5')}
                    textColor="black"
                    loading={loading}
                    loaderColor="black"
                    bgColor="white"
                    onPress={onSendOTP}
                    style={{ marginTop: 15 }}
                />
                </>
            )
        }
        else if( steps==1){
            return(
                <>
                <TextInput
                    value={opt}
                    error={submit && !opt}
                    onChangeText={(v) => setOtp(v)}
                    placeholder={"OTP"}
                />
                <CustomButton
                    text={i18n.t('G6')}
                    textColor="black"
                    loading={loading}
                    loaderColor="black"
                    bgColor="white"
                    onPress={onVerifyOTP}
                    style={{ marginTop: 15 }}
                />
                </>
            )
        }
        else{
            return(
                <>
                <TextInput
                    value={fields.email}
                    error={submit && !fields.email}
                    onChangeText={(v) => setFields({ ...fields, email: v })}
                    placeholder={i18n.t('LOGIN_TEXT2')}
                />
                <TextInput
                    error={submit && !fields.password}
                    value={fields.password}
                    onChangeText={(v) => setFields({ ...fields, password: v })}
                    placeholder={i18n.t('LOGIN_TEXT3')}
                    secureTextEntry={true}
                    style={{ marginTop: 15 }}
                />
                <TextInput
                    error={submit && !fields.confirm_password}
                    value={fields.confirm_password}
                    onChangeText={(v) => setFields({ ...fields, confirm_password: v })}
                    placeholder={i18n.t('SIGN_TEXT1')}
                    secureTextEntry={true}
                    style={{ marginTop: 15 }}
                />
                <CustomButton
                    text={i18n.t('LOGIN_TEXT4')}
                    textColor="black"
                    loading={loading}
                    loaderColor="black"
                    bgColor="white"
                    onPress={onSubmit}
                    style={{ marginTop: 15 }}
                />
                </>
            )
        }
    }
    return (
        <View style={{ flex: 1, width: '80%', alignSelf: 'center' }}>
            <View style={{marginBottom:20}}>
                <View style={{position:'absolute',top:5,zIndex:10}}>
                    <TouchableOpacity onPress={()=>props.setForget(false)}>
                    <Ionicons name="chevron-back-circle" size={24} color="white" />
                    </TouchableOpacity>
                </View>
                <CustomHeading
                    text={i18n.t('LOGIN_TEXT4')}
                    style={{ color: 'white', textAlign: 'center' }}
                />
            </View>
            {/* <Text style={styles.title}>Enter Vehicle Details</Text> */}

            {/* VEHICLE DETAILS INPUTS */}
            {renderStep()}
            {/* <CustomButton
                        text="Sign Up"
                        textColor="black"
                        loaderColor="black"
                        bgColor="white"
                        onPress={()=>props.navigation.push('signUp')}
                    /> */}
        </View>
    );
}

function mapStateToProps({ user }) {
    return { user }
}

export default connect(mapStateToProps, actions)(Login)

const styles = {
    title: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
        marginBottom: 12
    },
    whiteColor: {
        color: "white",
    },
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    body: {
        backgroundColor: "#f0f0f0",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        padding: 12,
        flex: 1,
        marginTop: -20,
    },
    card: {
        width: 250,
        height: 150,
        borderWidth: 1,
        borderColor: "gray",
        borderRadius: 10,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
    },
    cardTitle: {
        fontSize: 25,
        marginTop: 12,
    },
    cardBody: {
        fontColor: "gray",
    },
    brandCard: {
        height: 150,
        width: 120,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 12,
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
    },
    brandTitle: {
        marginTop: 16,
    },
    inputStyles: { marginTop: 12, backgroundColor: "#f0f0f0" },
};
