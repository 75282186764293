import { GET_USER_CARS } from "../actions/types";

const initialState=[]
export default function userCars(state = initialState, action) {
    switch (action.type) {
      case GET_USER_CARS:
        return action.payload;
      default:
        return state;
    }
  }