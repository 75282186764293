import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, TouchableOpacity,FlatList, StyleSheet, KeyboardAvoidingView, ScrollView, Dimensions, Linking, Modal } from "react-native";
import Header from "../components/Header";
import { FontAwesome } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import CustomButton from "../components/CustomButton";
import { TextInput } from "react-native-paper";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import { image_url } from "../myConfig/api.json"
import Loader from "../components/Loader"
import { Toast } from "native-base";
import ListingCard from "../components/ListingCard";
import Notfound from "../components/Notfound";
import CustomText from "../components/CustomText";
import i18n from "i18n-js";
import CustomHeading from "../components/Heading";
import { AntDesign, Entypo } from "@expo/vector-icons";
import MapView from 'react-native-maps';
import removeTags from "../utils/removeTags";
import Geolocation from 'react-native-geolocation-service';
import queryString from 'query-string';


function VendorDetails(props) {
    const [loading, setLoading] = useState(false)
    const [brands, setBrands] = useState([])
    const [brandModal, setBrandModal] = useState(false)
    const [coordinates, setCoordinates] = useState({latitude: "",longitude: ""});


    const getCoordinatesFromUrl = async (url) => {
  
      };

    useEffect(() => {
        setLoading(true)
        props.getBrands(props.route.params.id).then(res => {
            setBrands(res?.data?.makes)
        })
        Promise.all([props.getVendorDetails(props.route.params.id), props.getVendorListing(props.route.params.id), props.getReviews(props.route.params.id, true)])
            .then(() => {setLoading(false);
            })

            console.warn(props.vendorDetails);

    }, []);

    const [CsellerAddress,setSellerAddress] = useState('');

    useEffect(()=>{
        setSellerAddress(props.vendorDetails?.seller?.address);
          
   
    },[props.vendorDetails?.seller?.address]);

    useEffect(() => {
       if(CsellerAddress !== ""){
        console.warn('================');
        console.warn(props.vendorDetails?.seller?.address);
        console.warn('================');
      
        const encodedAddress = CsellerAddress;
        // const encodedAddress = "https://www.google.com/maps/place/Hamriyah+Power+Station/@25.4620163,55.4790109,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f56327198701b:0x2f3fb4298e1ba3ed!8m2!3d25.4620163!4d55.4790109!16s%2Fg%2F12m929ypq";
        const apiKey = 'AIzaSyCAcgEQMvho7rnMg-cV7wLEZjJLoH50ehk';

        // console.warn(encodedAddress);
        // const { latitude, longitude } = parsedUrl.query.ll.split(',');
        // Parse the query string from the URL
        if(typeof encodedAddress === 'string'){
        const parsedUrl = queryString.parseUrl(encodedAddress);
        var address = parsedUrl.url.split("/")[5];
        
        console.warn('COOOOOOOOOOOOOOOOORINDATE');
        console.warn(address);
        console.warn('COOOOOOOOOOOOOOOOORINDATE');
        }

        const fetchCoordinates = async () => {
          try {
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
            );
            const data = await response.json();
      
            if (data.status !== 'OK' || data.results.length === 0) {
              throw new Error('Unable to get coordinates for the address');
            }
      
            const location = data.results[0].geometry.location;
            setCoordinates({
              latitude: location.lat,
              longitude: location.lng,
            });

          } catch (error) {
            console.error(error);
          }
        };
      
        fetchCoordinates();
      
        return () => {};
       }
      }, [CsellerAddress]);



    // useEffect(() => {
    //     if (props.vendorDetails?.seller?.user_id) {
    //         props.getReviews(props.vendorDetails?.seller?.user_id)
    //             .then(() => setLoading(false))
    //     }
    // }, [props.vendorDetails])

    if (loading) {
        return <Loader />
    }
    // return (
    //     <View style={{ flex: 1 }}>
    //         <Header
    //             props={props}
    //             disableSearch={true}
    //             round={true}
    //             disableDrawer={true}
    //             goBack={true}
    //             disableNotifications={true}
    //         />
    //         <View style={{ padding: 12, alignItems: 'center', borderColor: 'lightgray', borderBottomWidth: 0.75 }}>
    //             <Image
    //                 style={{ width: 120, height: 120, borderRadius: 60 }}
    //                 source={(props.vendorDetails?.seller?.logo) ? { uri: `${image_url}/sellers/${props.vendorDetails.seller.logo}` } : require('../assets/placeholder.jpeg')}
    //             />
    //             {/* <Text style={styles.title}>{}</Text> */}
    //             <CustomHeading
    //             text={i18n.locale=="en"?props.vendorDetails.name:props.vendorDetails.ar_name}
    //             />
    //             <CustomText
    //                 style={{ fontSize: 10, color: 'gray' }}
    //                 text={`${i18n.t('LOGIN_TEXT2')}: ${props.vendorDetails.email}`}
    //             />
    //             <CustomText
    //                 style={{ fontSize: 10, color: 'gray' }}
    //                 text={`${i18n.t('SIGN_TEXT5')}: ${props.vendorDetails.phone}`}
    //             />
    //             {/* <Text>Email: {props.vendorDetails.email}</Text>
    //             <Text>Phone No: {props.vendorDetails.phone}</Text> */}
    //         </View>
    //         <View style={{ flex: 1 }}>
    //             <FlatList
    //                 data={props.vendorListing?.data}
    //                 showsVerticalScrollIndicator={false}
    //                 renderItem={({ item }) => <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}><ListingCard sellerList={true} vertical={true} item={item} props={props} style={{ marginTop: 12 }} /></View>}
    //                 keyExtractor={(item) => item.id.toString()}
    //                 ListEmptyComponent={Notfound}
    //             />
    //         </View>
    //     </View>
    // );
    return (
        <View style={{ flex: 1 }}>
            <KeyboardAvoidingView behavior="padding" style={{ flex: 1 }}>
                <Header round={true} navigation={props} goBack={true} />
                <View style={{ flex: 1, zIndex: -1 }}>
                    <TouchableOpacity
                        onPress={() => {
                            Linking.openURL(`tel:${props.vendorDetails?.seller?.phone}`)
                        }}
                        style={{
                            position: "absolute",
                            bottom: 20,
                            right: 20,
                            zIndex: 10,
                            backgroundColor: "#000000",
                            borderRadius: 50,
                            padding: 12,
                            width: 48,
                            justifyContent: "center",
                            alignItems: "center",

                        }}
                    >
                        <FontAwesome name="phone" size={24} color="white" />
                    </TouchableOpacity>
                    <ScrollView showsVerticalScrollIndicator={false} style={{ bottom: 12, zIndex: -1 }}>
                        {/* <View style={styles.sliderContainer} /> */}
                        {/* <FlatList
                  data={props.vendorDetails?.seller?.images}
                  pagingEnabled={true}
                  showsHorizontalScrollIndicator={false}
                  horizontal={true}
                  viewabilityConfig={{
                    itemVisiblePercentThreshold: 50,
                  }}
                  renderItem={({ item, i }) => {
                    return ( */}
                        <TouchableOpacity
                            style={{ width: Dimensions.get('screen').width }}
                        // key={item.id}
                        // onPress={() => props.props.navigation.navigate("ListingDetails", { id: item.id })}
                        >
                            <Image
                                source={(props.vendorDetails?.seller?.logo) ? { uri: `${image_url}/sellers/${props.vendorDetails?.seller?.logo}` } : require('../assets/placeholder.jpeg')}
                                //   key={i}
                                style={{ height: 210, width: Dimensions.get('screen').width }}
                                resizeMode="cover"
                            />
                        </TouchableOpacity>
                        {/* )
                  }}
                  onViewableItemsChanged={onChangeItem}
                  getItemLayout={getItemLayout}
                /> */}
                        <View style={{ width: '85%', alignSelf: 'center' }}>
                            <View>
                                {/* <View
            style={{
              borderColor: "black",
              borderRadius: 20,
              borderWidth: 1,
              paddingLeft: 12,
              paddingBottom: 6,
              paddingTop: 6,
              paddingRight: 12,
              width: 60,
            }}
          >
            <Text>{props?.vendorDetails?.seller?.start_year}</Text>
          </View> */}
                                <CustomHeading text={i18n.locale == "en" ? props.vendorDetails?.seller?.garage_name : props.vendorDetails?.seller?.ar_garage_name} />
                                <View style={{ flexDirection: "row", alignItems: "center",justifyContent:i18n.locale=="en"?'flex-start':'flex-end' }}>
                                    <Entypo name="star" size={16} color="black" />
                                    <Text style={{ ...styles.reviewTitle, marginLeft: 5, color: 'black' }}>{props.vendorDetails?.average_ratings}</Text>
                                </View>
                                <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT1')} />
                                <View style={{ minHeight: 80, borderRadius: 20, backgroundColor: 'white', color: 'gray', padding: 10, borderWidth: 1, borderColor: 'lightgray', }}>
                                    <CustomText style={{ fontSize: 11 }}
                                    text={i18n.locale == "en" ?removeTags(props.vendorDetails?.seller?.description):removeTags(props.vendorDetails?.seller?.ar_description)}
                                    />
                                </View>
                                <CustomButton
                                    text={i18n.t('C19')}
                                    textColor="black"
                                    bgColor="white"
                                    onPress={() => setBrandModal(true)}
                                    style={{ marginTop: 15, borderColor: 'lightgray', borderWidth: 1, borderRadius: 20 }}
                                />
                                {brandModal && (
                                    <Modal
                                        visible={brandModal}
                                        transparent={true}
                                        style={{ flex: 1 }}
                                    >
                                        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                                            <View style={{ backgroundColor: 'white', width: '85%', borderRadius: 20, padding: 15, height: 350 }}>
                                                <View style={{flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginBottom:20}}>
                                                <CustomHeading
                                                    text={i18n.t('G4')}
                                                />
                                                <TouchableOpacity
                                                onPress={()=>setBrandModal(false)}
                                                >
                                                    <Entypo
                                                    name="cross"
                                                    size={30}
                                                    color="black"
                                                    />
                                                </TouchableOpacity>
                                                </View>
                                                <ScrollView>
                                                    {brands.map((it, i) => {
                                                        return (
                                                            <View style={{flexDirection:'row',alignItems:'center'}}>
                                                                <Image
                                                                    source={{ uri: `${image_url}/makes/${it.image}` }}
                                                                    style={{ height: 60, width: 60 }}
                                                                    resizeMode="contain"
                                                                />
                                                                <CustomText
                                                                    text={i18n.locale == "en" ? it.title : it.ar_title}
                                                                    style={{marginLeft:20}}
                                                                />
                                                            </View>
                                                        )
                                                    })}
                                                </ScrollView>
                                            </View>
                                        </View>
                                    </Modal>
                                )}
                                <View>
                                    
                                    <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT6')} />
                                    <View style={{ height: 200, borderRadius: 20, borderColor: 'lightgray', borderWidth: 1, overflow: 'hidden' }}>
         {coordinates.latitude ?                            
         <MapView
  initialRegion={{
    latitude: Number(props.vendorDetails?.coordinates?.latitude ?? '25.2048'),
    longitude: Number(props.vendorDetails?.coordinates?.longitude ?? '55.2708'),
    latitudeDelta: 0.44,
    longitudeDelta: 0.44,
  }}
  region={{
    latitude: coordinates?.latitude ?? Number(props.vendorDetails?.coordinates?.latitude),
    longitude: coordinates?.longitude ?? Number(props.vendorDetails?.coordinates?.longitude),
    latitudeDelta: 0.44,
    longitudeDelta: 0.44,
  }}
  style={{ height: 200 }}
>
  <MapView.Marker
    coordinate={{
      latitude: coordinates?.latitude ?? Number(props.vendorDetails?.coordinates?.latitude),
      longitude: coordinates?.longitude ?? Number(props.vendorDetails?.coordinates?.longitude),
    }}
  />
</MapView> 
: <Text>Loading..</Text>}
                                    </View>
                                    <CustomButton
                                        text={i18n.t('G3')}
                                        textColor="black"
                                        bgColor="white"
                                        onPress={() => Linking.openURL(props.vendorDetails?.seller?.address)}
                                        style={{ marginTop: 15, borderColor: 'lightgray', borderWidth: 1, borderRadius: 20 }}
                                    />
                                    <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT3')} />
                                    {/* <Text style={styles.sectionTitles}>User Reviews</Text> */}
                                    <View>
                                        {(props.reviews?.data?.length) ? (
                                            <>
                                                {props.reviews?.data?.map((item, i) => {
                                                    return (
                                                        <View key={item.id} style={{ ...styles.reviewCards, marginTop: i == 0 ? 0 : 12, flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <CustomText
                                                            style={{ ...styles.reviewDesc, width: '90%' }}
                                                            text={item.text}
                                                            />
                                                            {/* <Text style={{ ...styles.reviewDesc, width: '90%' }}>
                                                                {item.text}
                                                            </Text> */}
                                                            <View style={{ flexDirection: "row", alignItems: "center" }}>
                                                                <Entypo name="star" size={16} color="black" />
                                                                <CustomText
                                                                style={styles.reviewTitle}
                                                                text={item.rating}
                                                                />
                                                                {/* <Text style={styles.reviewTitle}>{item.rating}</Text> */}
                                                            </View>
                                                        </View>
                                                    )
                                                })}
                                            </>
                                        ) : <Notfound />}
                                    </View>
                                </View>
                                <>
                                    <View style={{ flexDirection: i18n.locale=="en"?'row':'row-reverse', justifyContent: 'space-between', alignItems: 'center', marginVertical: 10 }}>
                                        <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT7')} />
                                        <TouchableOpacity
                                            onPress={() => props.navigation.navigate("Search", { id: props.vendorDetails?.seller?.user_id })}
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: 20,
                                                backgroundColor: 'white',
                                                marginHorizontal: 5,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                borderColor: 'lightgray',
                                                borderWidth: 1,
                                                shadowColor: "#000",
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.23,
                                                shadowRadius: 2.62,

                                                elevation: 4,
                                            }}>
                                            <FontAwesome name="filter" size={24} color="black" />
                                        </TouchableOpacity>
                                    </View>
                                </>
                            </View>
                        </View>
                        <>
                            <FlatList
                                horizontal={true}
                                data={props.vendorListing?.data}
                                showsHorizontalScrollIndicator={false}
                                inverted={i18n.locale=="en"?false:true}
                                renderItem={({ item, index }) => <ListingCard item={item} props={props} style={i18n.locale=="en"?{ marginLeft: index == 0 ? 30 : 10 }:{ marginRight: index == 0 ? 30 : 10 }} />}
                                keyExtractor={(item) => item.id.toString()}
                                ListEmptyComponent={Notfound}
                            />
                        </>
                    </ScrollView>
                </View>
            </KeyboardAvoidingView>
        </View>
    );
}

function mapStateToProps({ vendorDetails, vendorListing, reviews }) {
    return { vendorDetails, vendorListing, reviews }
}

export default connect(mapStateToProps, actions)(VendorDetails)

const styles = StyleSheet.create({
    title: {
        fontSize: 18,
        marginTop: 10,
        fontWeight: "bold",
        color: "black",
        // marginBottom: 12
    },
    whiteColor: {
        color: "white",
    },
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    body: {
        backgroundColor: "#f0f0f0",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        padding: 12,
        flex: 1,
        marginTop: -20,
    },
    card: {
        width: 250,
        height: 150,
        borderWidth: 1,
        borderColor: "gray",
        borderRadius: 10,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
    },
    cardTitle: {
        fontSize: 25,
        marginTop: 12,
    },
    cardBody: {
        color: "gray",
    },
    brandCard: {
        height: 150,
        width: 120,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 12,
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
        marginHorizontal: 5
    },
    brandTitle: {
        marginTop: 16,
    },
    reviewCards: {
        padding: 10,
        borderRadius: 20,
        borderColor: "lightgray",
        borderWidth: 1,
        backgroundColor: "white"
    },
    reviewTitle: {
        fontSize: 12,
        fontWeight: "bold",
        color: 'gray'
        // marginLeft: 8,
    },
    reviewDesc: {
        fontSize: 14,
        color: 'gray',
    },
    inputStyles: { marginTop: 12, backgroundColor: "#f0f0f0" },
})
