import React, { useEffect, useState } from "react"
import { Text, View, Image, Platform } from "react-native"
import CustomButton from "../components/CustomButton"
import i18n from 'i18n-js';
import * as Localization from 'expo-localization';
import AppLoading from 'expo-app-loading';
import Heading from "../components/Heading"
import CustomText from "../components/CustomText"
import * as actions from "../redux/actions/index"
import * as Updates from 'expo-updates';
import { connect } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Loader from "../components/Loader";
import {image_url} from "../myConfig/api.json"



function Splash(props) {
    const [loading,setLoading]=useState(false)
    const [img,setImg]=useState({})
    useEffect(()=>{
        props.getSplash().then(res=>{
            // console.log(res.data)
            setImg(res.data?.page)
            setLoading(false)
        })
    },[])
    const checkAndRedirect = async() =>{
        const language=await AsyncStorage.getItem('language')
        if(language){
            AsyncStorage.setItem('language',language=='en'?'ar':'en').then(()=>{
                Updates.reloadAsync()
            })
        }else{
            AsyncStorage.setItem('language','ar').then(()=>{
                Updates.reloadAsync()
            })
        }
    }

    if(loading){
        return <Loader/>
    }

    // console.log(image_url+"/pages/"+img.content)
    return (
        <View style={{ flex: 1, backgroundColor: "black" }}>
            {/* <Image style={{height:'30%'}} source={require("../assets/cars/spLogo.png")}/> */}
            <Image resizeMode="contain" style={{width:'100%',flex:1}} source={{uri:image_url+"/pages/"+(i18n.locale=="en"?img.content:img.ar_content)}}/>
            {/* <View style={{flex: 1, padding: 16, justifyContent:"flex-end"}}>
                <Heading
                text={i18n.t('WELCOME_HEADING')}
                
                style={{color:'white',fontSize:32}}
                />
                <Heading
                text={i18n.t('WELCOME_SUB_HEAD')}
                style={{color:'white'}}
                />
                <CustomText
                text={i18n.t('WELCOME_DES')}
                style={{color:'white',paddingVertical:20,width:'85%'}}
                />
            </View> */}
            <View style={{width:Platform.OS=="web"?480:'80%',alignSelf:'center',marginVertical:20}}>
                <CustomButton text={i18n.t('WELCOME_BTN')} textColor="black" bgColor="white" onPress={()=>props.navigation.navigate('Auth')}/>
            </View>
        </View>
    )
}

export default connect(null,actions)(Splash)

const styles = {
    heading: {
        fontSize: 35,
        color: "white",
        fontWeight: "bold"
    },
    subheading: {
        color: "gray",
        fontSize: 20
    }
}