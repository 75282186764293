import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, ScrollView, Dimensions } from "react-native";
import CustomButton from "../components/CustomButton";
import TextInput from "../components/TextInput";
import * as actions from "../redux/actions";
import CustomText from "../components/CustomText";
import { connect } from "react-redux";
import { TouchableOpacity } from "react-native-gesture-handler";
import i18n from 'i18n-js';

const { height } = Dimensions.get('window')
function Login(props) {
    const checkAndRedirect = () => props.navigation.navigate("Dashboard");
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({ email: "", password: "" })
    const [submit, setSubmit] = useState(false)



    function onSubmit() {
        setSubmit(true)
        if (fields.email && fields.password) {
            setLoading(true)
            props.userLogin(fields).then(() => setLoading(false))
        }
    }
    return (
        <View style={{ flex: 1, width: '80%', alignSelf: 'center' }}>
            {/* <Text style={styles.title}>Enter Vehicle Details</Text> */}

            {/* VEHICLE DETAILS INPUTS */}
            <TextInput
                value={fields.email}
                error={submit && !fields.email}
                onChangeText={(v) => setFields({ ...fields, email: v })}
                placeholder={i18n.t('LOGIN_TEXT2')}
            />
            <TextInput
                error={submit && !fields.password}
                value={fields.password}
                onChangeText={(v) => setFields({ ...fields, password: v })}
                placeholder={i18n.t('LOGIN_TEXT3')}
                secureTextEntry={true}
                style={{ marginTop: 15 }}
            />

            {props.user?.success ? null : <Text style={{ textAlign: 'center', width: '100%', color: 'white', marginVertical: 5, fontSize: 12,fontFamily:i18n.locale=="en"?'Poppins-Bold':'GE-SS-Unique-Bold' }}>{props.user.message}</Text>}
            <TouchableOpacity
                onPress={() => props.setForget(true)}
            >
                <CustomText
                    text={i18n.t('LOGIN_TEXT4')}
                    style={{ color: '#b9c7db', textAlign: 'center' }}
                />
            </TouchableOpacity>
            <CustomButton
                text={i18n.t('LOGIN_BTN')}
                textColor="black"
                loading={loading}
                loaderColor="black"
                bgColor="white"
                onPress={onSubmit}
                style={{ marginTop: 15 }}
            />
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                {
                    i18n.locale == "en" ? (
                        <>
                            <View>
                                <CustomText
                                    style={{ color: 'white', textAlign: 'center' }}
                                    text={`${i18n.t('LOGIN_TEXT5')} `}
                                />
                            </View>
                            <View>
                                <TouchableOpacity onPress={props.changeSlide}>
                                    <CustomText
                                        text={i18n.t('LOGIN_TEXT6')}
                                        style={{ color: '#b9c7db', textAlign: 'center' }}
                                    />
                                </TouchableOpacity>
                            </View>
                        </>
                    ) : (
                        <>
                            <View>
                                <TouchableOpacity onPress={props.changeSlide}>
                                    <CustomText
                                        text={i18n.t('LOGIN_TEXT6')}
                                        style={{ color: '#b9c7db', textAlign: 'center' }}
                                    />
                                </TouchableOpacity>
                            </View>
                            <View>
                                <CustomText
                                    style={{ color: 'white', textAlign: 'center' }}
                                    text={`${i18n.t('LOGIN_TEXT5')} `}
                                />
                            </View>
                        </>
                    )
                }
            </View>
            {/* <CustomButton
                        text="Sign Up"
                        textColor="black"
                        loaderColor="black"
                        bgColor="white"
                        onPress={()=>props.navigation.push('signUp')}
                    /> */}
        </View>
    );
}

function mapStateToProps({ user }) {
    return { user }
}

export default connect(mapStateToProps, actions)(Login)

const styles = {
    title: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
        marginBottom: 12
    },
    whiteColor: {
        color: "white",
    },
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    body: {
        backgroundColor: "#f0f0f0",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        padding: 12,
        flex: 1,
        marginTop: -20,
    },
    card: {
        width: 250,
        height: 150,
        borderWidth: 1,
        borderColor: "gray",
        borderRadius: 10,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
    },
    cardTitle: {
        fontSize: 25,
        marginTop: 12,
    },
    cardBody: {
        fontColor: "gray",
    },
    brandCard: {
        height: 150,
        width: 120,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 12,
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
    },
    brandTitle: {
        marginTop: 16,
    },
    inputStyles: { marginTop: 12, backgroundColor: "#f0f0f0" },
};
