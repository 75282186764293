import { StyleSheet, TouchableOpacity, View, Image, Alert, ActivityIndicator, Platform } from 'react-native'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as actions from "../redux/actions/index"
import i18n from "i18n-js";
import CustomHeading from "../components/Heading";
import CustomText from '../components/CustomText';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { image_url } from "../myConfig/api.json"
import Loader from '../components/Loader';

function ListImages({ list, setStep, setList, getListImages, deleteImage, uploadListImage }) {
    const [loading, setLoading] = useState(true)
    const [delLoading, setDelLoading] = useState(false)
    const [images, setImages] = useState([])
    const [current, setCurrent] = useState({})

    useEffect(() => {
        setLoading(true)
        getListImages(list?.id)
            .then((res) => {
                setImages(res?.data?.images)
                setLoading(false)
            })
    }, [])

    function onDelete(data) {
        setCurrent(data)
        setDelLoading(true)
        deleteImage(data?.id)
            .then(() => {
                setCurrent({})
                getListImages(list?.id)
                    .then((res) => {
                        setImages(res?.data?.images)
                        setLoading(false)
                        setDelLoading(false)
                    })
            })
    }

    async function uploadImage() {
        var options = {
            allowMultiSelection: false,
            type: 'image/*'
        };
        const DocumentPicker = Platform.OS == "web" ? require('../DocumentPickerWeb') : require('react-native-document-picker')
        const response = await DocumentPicker.pick(options);
        const body = new FormData()
        body.append('listing_id', list?.id)
        body.append('image', response[0])
        setLoading(true)
        uploadListImage(body).then(() => {
            getListImages(list?.id)
                .then((res) => {
                    setImages(res?.data?.images)
                    setLoading(false)
                })
        })
    }
    if (loading) {
        return <Loader />
    }
    return (
        <View style={{ flex: 1 }}>
            <View style={{ width: '100%', alignSelf: 'center', flex: 1 }}>
                <View style={{ width: '85%', alignSelf: 'center' }}>
                    <CustomHeading text={i18n.t('LISTING_IMAGE')} style={{ marginTop: 7.5, marginBottom: 10, textAlign: i18n.locale == "en" ? "left" : "right" }} />
                    {images?.map((it, i) => {
                        return (
                            <View key={i} style={{ marginTop: 10, justifyContent: 'center', alignItems: 'center' }}>
                                <Image
                                    source={{ uri: `${image_url}/listings/${it?.name}` }}
                                    resizeMode="contain"
                                    style={{ height: 150, backgroundColor: 'white', width: '100%', borderRadius: 17 }}
                                />
                                <View style={{ position: 'absolute' }}>
                                    {(delLoading && it?.id == current?.id) ? (
                                        <ActivityIndicator size={30} color={'black'} />
                                    ) : (
                                        <TouchableOpacity hitSlop={20} onPress={() => {
                                            Alert.alert(
                                                'Are you sure?',
                                                'You want to delete this image?',
                                                [
                                                    {
                                                        text: 'Cancel',
                                                        style: 'cancel',
                                                    },
                                                    {
                                                        text: 'Yes',
                                                        onPress: () => onDelete(it),
                                                    },
                                                ]
                                            );
                                        }}>
                                            <MaterialCommunityIcons name="delete" size={40} color={"red"} />
                                        </TouchableOpacity>
                                    )}
                                </View>
                            </View>
                        )
                    })}
                    <TouchableOpacity onPress={uploadImage}>
                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 5, height: 150, backgroundColor: "white", borderWidth: 1, borderStyle: "dashed", borderColor: "gray", borderRadius: 17, marginTop: 15 }}>
                            <>
                                <AntDesign name="cloudupload" size={44} color="black" />
                                <View><CustomText style={{ color: "gray" }} text={i18n.t('UPLOAD_ITEMS')} /></View>
                            </>
                        </View>

                    </TouchableOpacity>
                </View>
            </View>
        </View >
    )
}
function mapStateToProps({ user }) {
    return { user }
}
export default connect(mapStateToProps, actions)(ListImages)

const styles = StyleSheet.create({})