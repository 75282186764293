import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, ScrollView, TouchableOpacity, Dimensions, Platform } from "react-native";
import Header from "../components/Header";
import CustomButton from "../components/CustomButton";
import TextInput from "../components/TextInput";
import * as actions from "../redux/actions"
import CustomText from "../components/CustomText";
import Heading from "../components/Heading";
import { connect, useDispatch } from "react-redux";
import i18n from 'i18n-js';
import { Select, CheckIcon, extendTheme, NativeBaseProvider, Modal, Button } from "native-base";
import { AntDesign } from '@expo/vector-icons';
import { responsiveFontSize } from "react-native-responsive-dimensions";
import sliceText from "../utils/sliceText";
import { useIsFocused } from "@react-navigation/native";
import { LOGIN } from "../redux/actions/types";
import Loader from "../components/Loader";
import DropDownPicker from 'react-native-dropdown-picker';


function SignUp(props) {
    const checkAndRedirect = () => props.navigation.navigate("Dashboard");
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({
        userType: "seller",
        email: "",
        password: "",
        confirmPassword: "",
        name: "",
        phone: "",
        city: "",
        location: "",
        describtion: "",
        category: [],
        subCategory: [],
        subSubCategory: [],
        make: [],
    })
    const [submit, setSubmit] = useState(false)
    const [modalVisible, setModalVisible] = React.useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [pageLoading, setPageLoading] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const dispatch = useDispatch()
    const theme = extendTheme({
        components: {
            Select: {
                variants: {
                    rounded: ({
                    }) => {
                        return {
                            rounded: "full"
                        };
                    }
                }
            }
        }
    });

    useEffect(() => {
        if (props?.garage) {
            setPageLoading(true)
            Promise.all([props.getCategories(), props.getCars()])
                .then(() => setPageLoading(false))
        }
    }, [props?.garage])
    useEffect(() => {
        if (fields.category?.length > 0 && props?.garage) {
            setFields({ ...fields, subCategory: [] })
            props.getSubCategories(fields.category, true)
        }
    }, [fields.category, props?.garage])

    useEffect(() => {
        if (fields.subCategory?.length > 0 && props?.garage) {
            setFields({ ...fields, subSubCategory: [] })
            props.getSubSubCategories(fields.subCategory, true)
        }
    }, [fields.subCategory, props?.garage])

    async function uploadFile(uploadType) {
        var options = {
            allowMultiSelection: true,
            type: 'application/pdf'
        };

        if (uploadType == "image") {
            options.allowMultiSelection = false;
            options.type = 'image/*';
        }
        const DocumentPicker = Platform.OS == "web" ? require('../DocumentPickerWeb') : require('react-native-document-picker')
        const response = await DocumentPicker.pick(options);

        if (uploadType == 'image') {
            setUploadedLogo(response);
        }
        else {
            setUploadedFiles(response);
        }
    }


    function onSubmit() {
        setSubmit(true)
        if (props?.garage) {
            if (fields.email && fields.password && fields.password == fields.confirmPassword && fields.city && fields.phone && uploadedLogo && uploadedFiles.length > 0 && fields.location && fields.describtion && fields.name && fields.category.length > 0 && fields.subCategory.length > 0 && fields.subSubCategory.length > 0 && fields.make?.length > 0) {
                const payload = new FormData()
                payload.append('email', fields.email)
                payload.append('name', fields.name)
                payload.append('phone', fields.phone)
                payload.append('userType', fields.userType)
                payload.append('password', fields.password)
                payload.append('image', uploadedLogo[0])
                payload.append('about', fields.describtion)
                payload.append('city', fields.city)
                payload.append('address', fields.location)
                payload.append('categories', fields.category?.join(','))
                payload.append('subCategories', fields.subCategory?.join(','))
                payload.append('subSubCategories', fields.subSubCategory?.join(','))
                payload.append('makes', fields.make?.join(','))



                uploadedFiles.forEach(item => {
                    payload.append('docs[]', item)
                })

                setLoading(true)
                props.signUp(payload).then(() => {
                    setLoading(false)
                    setFields({ userType: "seller", email: "", password: "", confirmPassword: "", name: "", phone: "", city: "", location: "", describtion: "", category: [], subCategory: [], subSubCategory: [], make: [] })
                    setUploadedFiles([])
                    setUploadedLogo(null)
                    setSubmit(false)
                })
                    .catch(() => {
                        setLoading(false)
                    })
            }
        } else {
            if (fields.email && fields.password && fields.password == fields.confirmPassword && fields.phone && fields.name) {
                const payload = new FormData()
                payload.append('email', fields.email)
                payload.append('name', fields.name)
                payload.append('phone', fields.phone)
                payload.append('userType', "user")
                payload.append('password', fields.password)

                setLoading(true)
                props.signUp(payload).then(() => {
                    setLoading(false)
                    setFields({ userType: "user", email: "", password: "", confirmPassword: "", name: "", phone: "", city: "", location: "", describtion: "" })
                    setUploadedFiles([])
                    setUploadedLogo(null)
                    setSubmit(false)
                })
                    .catch(() => {
                        setLoading(false)
                    })
            }
        }
    }

    if (pageLoading) return <Loader />
    return (
        <NativeBaseProvider theme={theme}>
            <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)} size={"xl"}>
                <Modal.Content minH={"xl"} style={{ borderRadius: 25, padding: 10 }}>
                    <Modal.Body>
                        <ScrollView>
                            <View>
                                <Heading text={i18n.t('GARAGE_HEADING')} />
                                <CustomText style={{ marginTop: 5, color: "gray" }} text={i18n.t('GARAGE_DESCRIPTION')} />
                                <TouchableOpacity onPress={() => uploadFile('image')}>
                                    <View style={{ display: "flex", flexDirection: "column", justifyContent: uploadedLogo == null ? "center" : "flex-start", alignItems: uploadedLogo == null ? "center" : "flex-start", padding: 5, height: 150, backgroundColor: "white", borderWidth: 1, borderStyle: "dashed", borderColor: "black", borderRadius: 17, marginTop: 15 }}>

                                        {uploadedLogo == null ? <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <AntDesign name="cloudupload" size={44} color="black" />
                                            <CustomText style={{ color: "gray" }} text={i18n.t('UPLOAD_ITEMS')} />
                                        </View> : <View>

                                            <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'center', flex: 1 }}>
                                                <Image
                                                    source={{ uri: uploadedLogo[0]?.uri }}
                                                    resizeMode="contain"
                                                    style={{ height: 50, width: 50, marginLeft: 5 }}
                                                />
                                                <View>
                                                    <CustomText text={sliceText(uploadedLogo[0].name ?? "Unnamed", 15)} style={{ fontSize: 12, color: "gray", textWrap: "no-wrap", marginLeft: 8 }} />
                                                </View>
                                            </View>

                                        </View>}
                                        {
                                            uploadedLogo && (
                                                <TouchableOpacity
                                                    onPress={() => setUploadedLogo(null)}
                                                    hitSlop={10} style={{ position: 'absolute', top: 20, right: 20 }}>
                                                    <AntDesign name="close" style={{ color: 'black' }} size={20} />
                                                </TouchableOpacity>
                                            )
                                        }
                                    </View>

                                </TouchableOpacity>

                            </View>

                            {/* Garage documents */}
                            <View style={{ marginTop: 35 }}>
                                <Heading text={i18n.t('GARAGE_DOCUMENTS')} />
                                <CustomText style={{ marginTop: 5, color: "gray" }} text={i18n.t('GARAGE_DOCUMENT_DESCRIPTION')} />
                                <TouchableOpacity onPress={() => uploadFile('documents')}>
                                    <View style={{ display: "flex", flexDirection: "column", justifyContent: uploadedFiles.length == 0 ? "center" : "flex-start", alignItems: uploadedFiles.length == 0 ? "center" : "flex-start", padding: 5, height: 150, backgroundColor: "white", borderWidth: 1, borderStyle: "dashed", borderColor: "black", borderRadius: 17, marginTop: 15 }}>

                                        {uploadedFiles.length == 0 ? <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <AntDesign name="cloudupload" size={44} color="black" />
                                            <CustomText style={{ color: "gray" }} text={i18n.t('UPLOAD_ITEMS')} />
                                        </View> : <View>
                                            <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", flex: 1 }}>
                                                <AntDesign name="pptfile1" style={{ color: 'black' }} size={50} />
                                                {uploadedFiles.slice(0, 5).map((file) => {
                                                    return <CustomText text={sliceText((file.name).substring(0, 40) + ((file.name).length > 40 ? "..." : ""), 15)} style={{ fontSize: 12, color: "gray", textWrap: "no-wrap", marginLeft: 8 }} />
                                                })}
                                            </View>
                                        </View>}
                                        {
                                            uploadedFiles?.length > 0 && (
                                                <TouchableOpacity
                                                    onPress={() => setUploadedFiles([])}
                                                    hitSlop={10} style={{ position: 'absolute', top: 20, right: 20 }}>
                                                    <AntDesign name="close" style={{ color: 'black' }} size={20} />
                                                </TouchableOpacity>
                                            )
                                        }
                                    </View>




                                </TouchableOpacity>

                            </View>

                            <CustomButton
                                text={i18n.t('UPLOAD_BTN')}
                                textColor="white"
                                loading={loading}
                                loaderColor="black"
                                bgColor="black"
                                style={{ marginTop: 25 }}
                                onPress={() => setModalVisible(false)}
                            />
                            {/* Garage documents */}

                        </ScrollView>
                    </Modal.Body>

                </Modal.Content>
            </Modal>
            <View style={{ flex: 1, width: '80%', alignSelf: 'center' }}>
                {/* <Text style={styles.title}>Enter Vehicle Details</Text> */}

                {/* VEHICLE DETAILS INPUTS */}

                {/* {User Side} */}
                {props.garage ? <View>
                    <TextInput
                        value={fields.name}
                        error={submit && !fields.name}
                        onChangeText={(v) => setFields({ ...fields, name: v })}
                        placeholder={i18n.t('GARAGE_NAME')}
                    />
                    <Select
                        selectedValue={fields.city}
                        onValueChange={(v) => setFields({ ...fields, city: v })}
                        accessibilityLabel={i18n.t('S3')}
                        placeholder={i18n.t('S14')}
                        textAlign={i18n.locale == "en" ? "left" : "right"}
                        style={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }}
                        fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
                        mt={3}
                        fontSize={14}
                        borderRadius={30}
                        backgroundColor="white"
                        borderColor={submit && !fields.city ? "red.500" : "white.500"}  // Set your desired border color here
                        borderWidth={1}
                        height={39.9}
                        shadow={Platform.OS == "web" ? "none" : "1"}
                        _selectedItem={{
                            bg: "teal.600",
                            endIcon: <CheckIcon size={5}
                            />
                        }}>
                        <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY1')} label={i18n.t('CITY1')} value={"Abu Dhabi"} />
                        <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY2')} label={i18n.t('CITY2')} value={"Al Ain"} />
                        <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY3')} label={i18n.t('CITY3')} value={"Ajman"} />
                        <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY4')} label={i18n.t('CITY4')} value={"Dubai"} />
                        <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY5')} label={i18n.t('CITY5')} value={"Fujairah"} />
                        <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY6')} label={i18n.t('CITY6')} value={"Sharjah"} />
                        <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY7')} label={i18n.t('CITY7')} value={"Ras Al Khaimah"} />

                    </Select>
                    <TextInput
                        value={fields.location}
                        error={submit && !fields.location}
                        onChangeText={(v) => setFields({ ...fields, location: v })}
                        placeholder={i18n.t('LOCATION_LINK')}
                        style={{ marginTop: 15 }}
                    />
                    <TextInput
                        value={fields.phone}
                        error={submit && !fields.phone}
                        onChangeText={(v) => setFields({ ...fields, phone: v })}
                        placeholder={i18n.t('SIGN_TEXT5')}
                        style={{ marginTop: 15 }}
                    />


                    <TextInput
                        value={fields.describtion}
                        error={submit && !fields.describtion}
                        onChangeText={(v) => setFields({ ...fields, describtion: v })}
                        placeholder={i18n.t('ABOUT_GARAGE')}
                        style={{ marginTop: 15 }}
                        multiline={8}
                    />
                    <TextInput
                        value={fields.email}
                        error={submit && !fields.email}
                        placeholder={i18n.t('LOGIN_TEXT2')}
                        onChangeText={(v) => setFields({ ...fields, email: v })}
                        style={{ marginTop: 15 }}
                    />
                    <DropDownPicker
                        open={open4}
                        value={fields.make?.map(it => it)}
                        multiple={true}
                        items={[
                            { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                            ...props.cars?.map(item => ({
                                value: item.id,
                                label: i18n.locale == "en" ? item.title : item.ar_title
                            }))
                        ]}
                        setOpen={setOpen4}
                        placeholder={i18n.t('SERVICE_BRAND')}
                        placeholderStyle={{
                            color: 'gray', // Change placeholder color
                            fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                        }}
                        mode="BADGE"
                        listMode="MODAL"
                        onSelectItem={(v) => {
                            // Check if 'Select All' is selected
                            if (v?.some(it => it?.value === 'selectAll')) {
                                // Select all makes if 'Select All' is chosen
                                const allMakes = props.cars.map(item => item.id);
                                setFields({ ...fields, make: allMakes });
                            } else {
                                // Normal selection for individual makes
                                setFields({ ...fields, make: v?.map(it => it?.value) });
                            }
                        }}
                        textStyle={{
                            fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                        }}
                        textc
                        containerStyle={{ paddingTop: 0 }}
                        style={{
                            borderColor: (submit && !fields.make?.length > 0) ? "red" : "lightgray",
                            borderRadius: 30,
                            minHeight: 45,
                            marginVertical: 4,
                            marginTop: 15
                        }}
                        badgeStyle={{
                            backgroundColor: 'lightgray',
                            borderRadius: 30
                        }}
                        badgeDotColors={"black"}
                    />
                    <View>
                        <DropDownPicker
                            open={open1}
                            value={fields.category?.map(it => it)}
                            multiple={true}
                            placeholder={i18n.t('SERVICE_CAT')}
                            placeholderStyle={{
                                color: 'gray', // Change placeholder color
                                fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                            }}
                            items={[
                                { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                                ...props.categories?.map(item => ({
                                    value: item.id,
                                    label: i18n.locale == "en" ? item.title : item.ar_title
                                }))
                            ]}
                            setOpen={setOpen1}
                            mode="BADGE"
                            listMode="MODAL"
                            onSelectItem={(v) => {
                                // Check if 'Select All' is selected
                                if (v?.some(it => it?.value === 'selectAll')) {
                                    // Select all categories if 'Select All' is chosen
                                    const allCategories = props.categories.map(item => item.id);
                                    setFields({ ...fields, category: allCategories });
                                } else {
                                    // Normal selection for individual categories
                                    setFields({ ...fields, category: v?.map(it => it?.value) });
                                }
                            }}
                            textStyle={{
                                fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                            }}
                            textc
                            containerStyle={{ paddingTop: 0 }}
                            style={{
                                borderColor: (submit && !fields.category?.length > 0) ? "red" : "lightgray",
                                borderRadius: 30,
                                minHeight: 45,
                                marginVertical: 8,
                                marginTop: 15
                            }}
                            badgeStyle={{
                                backgroundColor: 'lightgray',
                                borderRadius: 30,
                                borderRadius: 30
                            }}
                            badgeDotColors={"black"}
                        />
                    </View>
                    <DropDownPicker
                        disabled={!fields.category?.length > 0}
                        open={open2}
                        value={fields.subCategory?.map(it => it)}
                        multiple={true}
                        placeholder={i18n.t('SUB_CAT')}
                        placeholderStyle={{
                            color: 'gray', // Change placeholder color
                            fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                        }}
                        items={[
                            { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                            ...props.subCategories?.map(item => ({
                                value: item.id,
                                label: i18n.locale == "en" ? item.title : item.ar_title
                            }))
                        ]}
                        setOpen={setOpen2}
                        mode="BADGE"
                        listMode="MODAL"
                        onSelectItem={(v) => {
                            // Check if 'Select All' is selected
                            if (v?.some(it => it?.value === 'selectAll')) {
                                // Select all subcategories if 'Select All' is chosen
                                const allSubCategories = props.subCategories.map(item => item.id);
                                setFields({ ...fields, subCategory: allSubCategories });
                            } else {
                                // Normal selection for individual subcategories
                                setFields({ ...fields, subCategory: v?.map(it => it?.value) });
                            }
                        }}
                        textStyle={{
                            fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                        }}
                        textc
                        containerStyle={{ paddingTop: 0 }}
                        style={{
                            borderColor: (submit && !fields.subCategory?.length > 0) ? "red" : "lightgray",
                            borderRadius: 30,
                            minHeight: 45,
                            marginVertical: 10,
                            backgroundColor: !fields.category?.length > 0 ? '#f2f2f2' : 'white'
                        }}
                        badgeStyle={{
                            backgroundColor: 'lightgray',
                            borderRadius: 30,
                        }}
                        badgeDotColors={"black"}
                    />
                    <DropDownPicker
                        disabled={!fields.subCategory?.length > 0}
                        open={open3}
                        value={fields.subSubCategory?.map(it => it)}
                        multiple={true}
                        items={[
                            { value: 'selectAll', label: i18n.t('SELECT_ALL') }, // Add Select All option
                            ...props.subSubCategories?.map(item => ({
                                value: item.id,
                                label: i18n.locale == "en" ? item.title : item.ar_title
                            }))
                        ]}
                        setOpen={setOpen3}
                        mode="BADGE"
                        placeholder={i18n.t('SUB_SUB_CAT')}
                        placeholderStyle={{
                            color: 'gray', // Change placeholder color
                            fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                        }}
                        listMode="MODAL"
                        onSelectItem={(v) => {
                            // Check if 'Select All' is selected
                            if (v?.some(it => it?.value === 'selectAll')) {
                                // Select all sub-subcategories if 'Select All' is chosen
                                const allSubSubCategories = props.subSubCategories.map(item => item.id);
                                setFields({ ...fields, subSubCategory: allSubSubCategories });
                            } else {
                                // Normal selection for individual sub-subcategories
                                setFields({ ...fields, subSubCategory: v?.map(it => it?.value) });
                            }
                        }}
                        textStyle={{
                            fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold",
                        }}
                        textc
                        containerStyle={{ paddingTop: 0 }}
                        style={{
                            borderColor: (submit && !fields.subSubCategory?.length > 0) ? "red" : "lightgray",
                            borderRadius: 30,
                            minHeight: 45,
                            marginVertical: 10,
                            backgroundColor: !fields.subCategory?.length > 0 ? '#f2f2f2' : 'white'
                        }}
                        badgeStyle={{
                            backgroundColor: 'lightgray',
                            borderRadius: 30
                        }}
                        badgeDotColors={"black"}
                    />
                    <TextInput
                        value={fields.password}
                        error={submit && !fields.password}
                        placeholder={i18n.t('LOGIN_TEXT3')}
                        onChangeText={(v) => setFields({ ...fields, password: v })}
                        secureTextEntry={true}
                        style={{ marginTop: 15 }}
                    />
                    <TextInput
                        value={fields.confirmPassword}
                        error={submit && !fields.confirmPassword}
                        placeholder={i18n.t('SIGN_TEXT1')}
                        onChangeText={(v) => setFields({ ...fields, confirmPassword: v })}
                        secureTextEntry={true}
                        style={{ marginTop: 15 }}
                    />

                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: 15 }}>
                        <View style={[styles.grayline, { width: "30%" }]} />
                        <CustomText
                            text={i18n.t('GARAGE_DOCUMENTS')}
                            style={{ color: 'white', paddingHorizontal: 12, marginTop: 10 }}
                        />
                        <View style={[styles.grayline, { width: "30%" }]} />

                    </View>

                    <TouchableOpacity onPress={() => setModalVisible(true)}>
                        <View style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 5, height: 150, backgroundColor: "white", borderWidth: 1, borderStyle: "dashed", borderColor: (submit && (!uploadedFiles?.length > 0 || !uploadedLogo) ? "red" : "gray"), borderRadius: 17, marginTop: 15 }}>
                            {(uploadedFiles?.length > 0 || uploadedLogo) ? (
                                <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                                    {uploadedLogo ? (
                                        <View>
                                            <View style={{ marginTop: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', backgroundColor: 'lightgray', padding: 5, marginRight: 5, width: 130, height: 80 }}>
                                                <Image
                                                    source={{ uri: uploadedLogo[0]?.uri }}
                                                    resizeMode="contain"
                                                    style={{ height: 50, width: 50, marginLeft: 5 }}
                                                />
                                                <CustomText text={sliceText(uploadedLogo[0].name ?? "Unnamed", 10)} style={{ fontSize: 12, color: "gray", textWrap: "no-wrap", marginLeft: 8 }} />
                                            </View>
                                            <TouchableOpacity
                                                onPress={() => setUploadedLogo(null)}
                                                hitSlop={10} style={{ position: 'absolute', top: 10, right: 10 }}>
                                                <AntDesign name="close" style={{ color: 'black' }} size={20} />
                                            </TouchableOpacity>
                                        </View>
                                    ) : (
                                        <TouchableOpacity onPress={() => setModalVisible(true)} style={{ marginTop: 5, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', backgroundColor: 'lightgray', padding: 5, marginRight: 5, width: 130, height: 80 }}>
                                            <AntDesign name="cloudupload" size={44} color="black" />
                                            <CustomText style={{ color: "black" }} text={i18n.t('UPLOAD_LOGO')} />
                                        </TouchableOpacity>
                                    )}
                                    {
                                        uploadedFiles.length > 0 ? (
                                            <View>
                                                <View style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: 'lightgray', padding: 5, width: 130, height: 80 }}>
                                                    <AntDesign name="pptfile1" style={{ color: 'black' }} size={50} />
                                                    {uploadedFiles.slice(0, 5).map((file) => {
                                                        return <CustomText text={sliceText((file.name).substring(0, 40) + ((file.name).length > 40 ? "..." : ""), 14)} style={{ fontSize: 8, color: "gray", textWrap: "no-wrap", marginLeft: 8 }} />
                                                    })}
                                                </View>
                                                <TouchableOpacity
                                                    onPress={() => setUploadedFiles([])}
                                                    hitSlop={10} style={{ position: 'absolute', top: 10, right: 10 }}>
                                                    <AntDesign name="close" style={{ color: 'black' }} size={20} />
                                                </TouchableOpacity>
                                            </View>
                                        ) : (
                                            <TouchableOpacity onPress={() => setModalVisible(true)} style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: 'lightgray', padding: 5, width: 130, height: 80 }}>
                                                <AntDesign name="cloudupload" size={44} color="black" />
                                                <CustomText style={{ color: "black" }} text={i18n.t('UPLOAD_DOCS')} />
                                            </TouchableOpacity>
                                        )
                                    }
                                </View>
                            ) : (
                                <>
                                    <AntDesign name="cloudupload" size={44} color="black" />
                                    <View>
                                        <CustomText style={{ color: "gray" }} text={i18n.t('UPLOAD_ITEMS')} />
                                    </View>
                                </>
                            )}
                        </View>

                    </TouchableOpacity>


                </View> : <View>
                    <TextInput
                        value={fields.name}
                        error={submit && !fields.name}
                        onChangeText={(v) => setFields({ ...fields, name: v })}
                        placeholder={i18n.t('SIGN_TEXT4')}
                    />
                    <TextInput
                        value={fields.email}
                        error={submit && !fields.email}
                        onChangeText={(v) => setFields({ ...fields, email: v })}
                        placeholder={i18n.t('LOGIN_TEXT2')}
                        style={{ marginTop: 15 }}
                    />
                    <TextInput
                        value={fields.phone}
                        error={submit && !fields.phone}
                        onChangeText={(v) => setFields({ ...fields, phone: v })}
                        placeholder={i18n.t('SIGN_TEXT5')}
                        style={{ marginTop: 15 }}
                    />


                    <TextInput
                        value={fields.password}
                        error={submit && !fields.password}
                        onChangeText={(v) => setFields({ ...fields, password: v })}
                        secureTextEntry={true}
                        placeholder={i18n.t('LOGIN_TEXT3')}
                        style={{ marginTop: 15 }}
                    />
                    <TextInput
                        value={fields.confirmPassword}
                        error={submit && !fields.confirmPassword}
                        placeholder={i18n.t('SIGN_TEXT1')}
                        onChangeText={(v) => setFields({ ...fields, confirmPassword: v })}
                        secureTextEntry={true}
                        style={{ marginTop: 15 }}
                    />

                </View>}
                {/* {User Side} */}

                {fields.password == fields.confirmPassword ? null : <Text style={{ textAlign: 'center', width: '100%', color: 'white', marginVertical: 5, fontSize: 12, fontFamily: i18n.locale == "en" ? 'Poppins-Bold' : 'GE-SS-Unique-Bold' }}>{(i18n.t('PASS_ERR'))}</Text>}
                {props.user?.success ? null : <Text style={{ textAlign: 'center', width: '100%', color: 'white', marginVertical: 5, fontSize: 12, fontFamily: i18n.locale == "en" ? 'Poppins-Bold' : 'GE-SS-Unique-Bold' }}>{props.user.message}</Text>}
                <CustomButton
                    text={i18n.t('SIGN_BTN')}
                    textColor="black"
                    loading={loading}
                    loaderColor="black"
                    bgColor="white"
                    onPress={onSubmit}
                />
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                    {
                        i18n.locale == "en" ? (
                            <>
                                <View>
                                    <CustomText
                                        style={{ color: 'white', textAlign: 'center' }}
                                        text={`${i18n.t('SIGN_TEXT2')} `}
                                    />
                                </View>
                                <View>
                                    <TouchableOpacity onPress={props.changeSlide}>
                                        <CustomText
                                            text={i18n.t('SIGN_TEXT3')}
                                            style={{ color: '#b9c7db' }}
                                        />
                                    </TouchableOpacity>
                                </View>
                            </>
                        ) : (
                            <>
                                <View>
                                    <TouchableOpacity onPress={props.changeSlide}>
                                        <CustomText
                                            text={i18n.t('SIGN_TEXT3')}
                                            style={{ color: '#b9c7db' }}
                                        />
                                    </TouchableOpacity>
                                </View>
                                <View>
                                    <CustomText
                                        style={{ color: 'white', textAlign: 'center' }}
                                        text={`${i18n.t('SIGN_TEXT2')} `}
                                    />
                                </View>
                            </>
                        )
                    }
                </View>
            </View>
        </NativeBaseProvider>
    );
}

function mapStateToProps({ user, categories, subCategories, subSubCategories, cars }) {
    return { user, categories, subCategories, subSubCategories, cars }
}

export default connect(mapStateToProps, actions)(SignUp)

const styles = {
    grayline: {
        backgroundColor: "#c0c0c0",
        flex: 0.4,
        height: 1
    },
    title: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
        marginBottom: 12
    },
    whiteColor: {
        color: "white",
    },
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    body: {
        backgroundColor: "#f0f0f0",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        padding: 12,
        flex: 1,
        marginTop: -20,
    },
    card: {
        width: 250,
        height: 150,
        borderWidth: 1,
        borderColor: "gray",
        borderRadius: 10,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
    },
    cardTitle: {
        fontSize: 25,
        marginTop: 12,
    },
    cardBody: {
        fontColor: "gray",
    },
    brandCard: {
        height: 150,
        width: 120,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 12,
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
    },
    brandTitle: {
        marginTop: 16,
    },
    inputStyles: { marginTop: 12, backgroundColor: "#f0f0f0" },
};
