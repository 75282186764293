import { StyleSheet, Text, View ,ActivityIndicator} from 'react-native'
import React from 'react'
import i18n from 'i18n-js'

export default function Notfound({color}) {
  return (
    <View style={styles.con}>
      <Text style={{fontSize:14,color:'gray',fontFamily:i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light"}}>{i18n.t('NDF')}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
    con:{
        flex:1,
        marginTop:5,
        justifyContent:'center',
        alignItems:'center'
    }
})