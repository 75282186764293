
import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
  Animated,
  TouchableOpacity,
  FlatList
} from "react-native";
import Header from "../components/Header";
import {
  Entypo,
  AntDesign,
  FontAwesome,
  MaterialIcons
} from "@expo/vector-icons";
import ListingCard from "../components/ListingCard";
import { connect } from "react-redux";
import * as actions from "../redux/actions"
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import Loader from "../components/Loader"
import { image_url } from "../myConfig/api.json"
import CustomText from "../components/CustomText";
import CustomHeading from "../components/Heading";
import i18n from "i18n-js";
import { responsiveFontSize, responsiveHeight } from "react-native-responsive-dimensions";
import WebView from 'react-native-webview';



function SellerProfile(props) {
  const [loading, setLoading] = useState(false);

  // console.log('cars',props.userCars)

  if (loading) {
    return <Loader />
  }

  return (
    <View style={{ flex: 1, width: '100%', backgroundColor: 'white' }}>
      <Header navigation={props} />
      <WebView cacheEnabled={false}
  cacheMode={'LOAD_NO_CACHE'} startInLoadingState={true} source={{ uri: `https://app.gearz.ae/seller/profile?user_id=${props.user.user.id}` }} />

    </View>
  );
}

function mapStateToProps({ user }) {
  return { user}
}
export default connect(mapStateToProps, actions)(SellerProfile)

const styles = {
  title: {
    fontSize: 25,
    fontWeight: "bold",
    color: "white",
  },
  whiteColor: {
    color: "white",
  },
  titleBlack: {
    fontSize: 25,
    fontWeight: "bold",
    color: "black",
  },
  body: {
    backgroundColor: "#f0f0f0",
    borderTopRightRadius: 40,
    borderTopLeftRadius: 40,
    // flex:1,
    minHeight:responsiveHeight(80),
    marginTop: -10,
    paddingBottom:10
  },
  card: {
    flex: 1,
    height: 80,
    width: 95,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 10,
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
  },
  cardTitle: {
    fontSize: 10,
    marginTop: 8,
    textAlign: "center"
  },
  cardBody: {
    fontColor: "gray",
  },
};
