import { GET_VENDORS } from "../actions/types";

const initialState={}
export default function vendors(state = initialState, action) {
    switch (action.type) {
      case GET_VENDORS:
        return action.payload;
      default:
        return state;
    }
  }