// DocumentPickerWeb.js
export const pick = async () => {
    return new Promise((resolve) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = (e) => {
        const file = e.target.files[0];
        resolve([file]); // resolve with file array to match native picker behavior
      };
      input.click();
    });
  };
  
  export default {
    pick,
    types: {
      allFiles: '*/*',
    },
    isCancel: () => false, // No cancellation logic for the web version
  };
  