import { StyleSheet, Text, View } from 'react-native'
import i18n from 'i18n-js';
import React from 'react'

export default function CustomHeading({text,style={},removeMargin}) {
  return (
      <View>
          <Text style={{...styles.text,fontFamily:i18n.locale=="en"?'Poppins-Medium':'GE-SS-Unique-Bold',marginRight:i18n.locale=="en"?0:7,textAlign:i18n.locale=="en"?"left":(removeMargin?"left":"right"),...style}}>{text}</Text>
      </View>
  )
}

const styles = StyleSheet.create({
    text:{
        fontSize:21.88,
        includeFontPadding:false,
    }
})