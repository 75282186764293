import { CAR_LISTING } from "../actions/types";

const initialState={}
export default function carListing(state = initialState, action) {
    switch (action.type) {
      case CAR_LISTING:
        return action.payload;
      default:
        return state;
    }
  }