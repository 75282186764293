import { StyleSheet, Text, View, FlatList, Image,TouchableOpacity, Dimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { connect } from 'react-redux'
import * as actions from "../redux/actions"
import Loader from '../components/Loader'
import { image_url } from "../myConfig/api.json"
import ListingCard from '../components/ListingCard'
import Notfound from '../components/Notfound'

function SubCategories(props) {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // alert(JSON.stringify({
        //     'category_ids[]':props.route.params.cat,
        //     'sub_category_ids[]':props.route.params.cat2,
        //     'car_id':props.route.params.carId,
        //     'sub_sub_category_ids[]':props.route.params.catId
        // }))
        setLoading(true)
     console.warn(props.route.params.cat);
     console.warn(props.route.params.cat2);
     console.warn(props.route.params.carId);
     console.warn(props.route.params.catId);
     props.getCarListing({
            'category_ids[]':props.route.params.cat,
            'sub_category_ids[]':props.route.params.cat2,
            'car_id':props.route.params.carId,
            'sub_sub_category_ids[]':props.route.params.catId
        }).then(() => setLoading(false));
    }, []);

    return (
        <View style={{flex:1}}>
            <Header
                goBack={true}
                props={props}
                disableSearch={true}
                round={true}
                disableDrawer={true}
                disableNotifications={true}
            />
            <View style={{flex:1}}>
                {/* <Text style={[styles.titleBlack, { marginTop: 12, textTransform: 'capitalize' }]}>
                    {props.route.params.title}
                </Text> */}
                {loading ? (
                    <View style={{ height: 100, justifyContent: 'center', alignItems: 'center' }}>
                        <Loader />
                    </View>
                ) : (
                    <FlatList
                        data={props.carListing?.data}
                        renderItem={({item})=> <View style={{width:'100%',flexDirection:'row',justifyContent:'center'}}><ListingCard vertical={true} item={item} props={props} style={{ marginTop: 12 }} /></View>}
                        keyExtractor={(item) => item.id}
                        ListEmptyComponent={Notfound}
                    />
                )}
            </View>
        </View>
    )
}

function mapStateToProps({ carListing }) {
    return { carListing }
}
export default connect(mapStateToProps, actions)(SubCategories)
const styles = StyleSheet.create({
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
})