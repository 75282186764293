import React, { useEffect, useState } from "react";
import { SafeAreaView, StatusBar, Platform, Text } from "react-native";
import Router from "./configs/Router";
import { NativeBaseProvider } from "native-base";
import "react-native-gesture-handler";
import store from "./redux/store"
import { Provider } from "react-redux";
// import AppLoading from 'expo-app-loading';
import * as SplashScreen from 'expo-splash-screen';
import { useFonts } from 'expo-font';
import { extendTheme } from 'native-base';
import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from "i18n-js";
SplashScreen.preventAutoHideAsync(); // Keep the splash screen visible while we fetch resources


export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);
  const [langLoading, setLanLoading] = useState(true);
  const [loaded] = useFonts({
    'Poppins-Regular': require('./assets/fonts/Poppins-Regular.ttf'),
    'Poppins-Medium': require('./assets/fonts/Poppins-Medium.ttf'),
    'Poppins-Bold': require('./assets/fonts/Poppins-Bold.ttf'),
    'GE-SS-Unique-Light': require('./assets/fonts/GE-SS-Unique-Light.otf'),
    'GE-SS-Unique-Bold': require('./assets/fonts/GE-SS-Unique-Bold.otf'),
  });

  const cacheResourcesAsync = async () => {
    // Load your fonts or other resources here
    await Promise.all([
      // Font loading code goes here
    ]);
  };

  useEffect(() => {
    const prepareResourcesAsync = async () => {
      try {
        await SplashScreen.preventAutoHideAsync();

        // Load resources
        await cacheResourcesAsync();

        // Resources are loaded, mark app as ready
        setAppIsReady(true);
      } catch (error) {
        console.warn(error);
      } finally {
        // Hide the splash screen
        await SplashScreen.hideAsync();
      }
    };

    prepareResourcesAsync();
  }, []);

  useEffect(() => {
    setLanLoading(true);
    AsyncStorage.getItem('language').then((res) => {
      if (res) {
        i18n.locale == 'ar';
        setLanLoading(false);
      }
    });
  }, []);


  // if (!loaded && langLoading) {
  //   return <AppLoading />
  // }



  return (
    <>
      <StatusBar barStyle={"light-content"} backgroundColor={"black"} />
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: 'black'
        }
        }
      >
        <Provider store={store}>
          <NativeBaseProvider theme={extendTheme({
            fontConfig: {
              Poppins: {
                100: {
                  normal: 'Poppins-Medium'
                }
              },
              GE: {
                100: {
                  normal: 'GE-SS-Unique-Bold'
                }
              },
            },

            // Make sure values below matches any of the keys in `fontConfig`
            fonts: {
              heading: 'Poppins',
              body: 'GE',
              customFont: 'GE',
            },
          })}>
          <Router />
        </NativeBaseProvider>
      </Provider>
    </SafeAreaView>
    </>
  );
}
