import { LOGIN, REG_CAR, UPDATE_GARAGE } from "../actions/types";

const initialState = {}
export default function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    case UPDATE_GARAGE:
      return { ...state, garage: action.payload };
    case REG_CAR:
      return { ...state, user: { ...state.user, total_cars_count: 1 } };
    default:
      return state;
  }
}