import { GET_VENDOR_DETAILS } from "../actions/types";

const initialState={}
export default function vendorDetails(state = initialState, action) {
    switch (action.type) {
      case GET_VENDOR_DETAILS:
        return action.payload;
      default:
        return state;
    }
  }