import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, ScrollView, TouchableOpacity } from "react-native";
import Header from "../components/Header";
import { FontAwesome } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import CustomButton from "../components/CustomButton";
import { TextInput } from "react-native-paper";
import { Searchbar } from "react-native-paper";
import ListingDetails from "./ListingDetails";
import {
  Select,
  VStack,
  CheckIcon,
  Center,
  NativeBaseProvider,
} from "native-base";
import ListingCard from "../components/ListingCard";

export default function Listings(props) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const onChangeSearch = (query) => setSearchQuery(query);

  const [items, setItems] = useState([]);
  let [year, setYear] = React.useState("");
  let [brand, setBrand] = React.useState("");
  let [prices, setPrices] = React.useState("");

  useEffect(() => {
    let items = Array.apply(null, Array(60)).map((v, i) => {
      //Loop to make image array to show in slider
      return {
        source: require("../assets/cars/taycan.png"),
      };
    });
    setItems(items);
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <Header navigation={props} goBack={true} />
      <View style={{ flex: 1 }}>
        <ScrollView horizontal={true} style={{ marginTop: 12 }}>
          <TouchableOpacity style={styles.roundedFilters}>
            <Ionicons name="options" size={18} color="black" />
          </TouchableOpacity>
          <Select
            selectedValue={year}
            minWidth="100"
            variant="rounded"
            accessibilityLabel="Year"
            placeholder="Year"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size="5" />,
            }}
            borderColor="black"
            onValueChange={(itemValue) => setYear(itemValue)}
          >
            <Select.Item label="2020" value="ux" />
            <Select.Item label="2019" value="web" />
            <Select.Item label="2018" value="cross" />
          </Select>
          <Select
            selectedValue={brand}
            minWidth="100"
            variant="rounded"
            accessibilityLabel="Brand"
            placeholder="Brand"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size="5" />,
            }}
            ml={1}
            borderColor="black"
            onValueChange={(itemValue) => setBrand(itemValue)}
          >
            <Select.Item label="Honda" value="ux" />
            <Select.Item label="Toyota" value="web" />
            <Select.Item label="Audi" value="cross" />
          </Select>
          <Select
            selectedValue={prices}
            minWidth="100"
            variant="rounded"
            accessibilityLabel="Prices"
            placeholder="Prices"
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size="5" />,
            }}
            ml={1}
            borderColor="black"
            onValueChange={(itemValue) => setPrices(itemValue)}
          >
            <Select.Item label="1000-5000" value="ux" />
            <Select.Item label="5000-10000" value="web" />
            <Select.Item label="10000-20000" value="cross" />
          </Select>
        </ScrollView>
        <ScrollView>
          <ScrollView style={{ marginTop: 12, padding: 12 }}>
            <ListingCard props={props} />

            <ListingCard props={props} style={{ marginTop: 12 }} />

            <ListingCard props={props} style={{ marginTop: 12 }} />

            <ListingCard props={props} style={{ marginTop: 12 }} />
          </ScrollView>
        </ScrollView>
      </View>
    </View>
  );
}

const styles = {
  listingCard: {
    height: 300,
    padding: 12,
    backgroundColor: "white",
    borderRadius: 12,
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
  },
  sliderContainer: {
    height: 170,
    backgroundColor: "#00000000",
  },
  listingTitle: {
    fontSize: 18,
    marginTop: 12,
  },
  listingOffer: {
    fontSize: 20,
    marginTop: 12,
  },
  listingDesc: {
    color: "gray",
  },
  roundedFilters: {
    paddingLeft: 12,
    paddingRight: 12,
    height: 40,
    borderRadius: 12,
    borderWidth: 1,
    marginLeft: 6,
    marginRight: 6,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
};
