import React, { useState, useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import {
  createStackNavigator,
  TransitionSpecs,
  CardStyleInterpolators,
} from "@react-navigation/stack";
import Dashbaord from "../screens/Dashboard";
import Splash from "../screens/Splash";
import Auth from "../screens/Auth";
import CreateVehicle from "../screens/CreateVehicle";
import Search from "../screens/Search";
import Listings from "../screens/Listings";
import ListingDetails from "../screens/ListingDetails";
import SellerProfile from "../screens/SellerProfile";
import CustomDrawer from "../components/CustomDrawer";
import VendorListings from "../screens/VendorListings";
import VehicleDetails from "../screens/VehicleDetails";
import { View, ActivityIndicator } from "react-native";
import * as actions from "../redux/actions";
import { connect } from "react-redux";
import Login from "../screens/login";
import SignUp from "../screens/signUp";
import SubCategories from "../screens/SubCategories";
import Profile from "../screens/Profile";
import Service from "../screens/Service";
import Vendor from "../screens/Vendor";
import vendorDetails from "../screens/vendorDetails";
import CarListing from "../screens/CarListing";
import Notifications from "../screens/Notifications";
import * as Localization from "expo-localization";
import i18n from "i18n-js";
import en from "../localization/en";
import ar from "../localization/ar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import GarageCategory from "../screens/GarageCategory";
import GarageDashboard from "../screens/GarageDashboard";
import Settings from "../screens/Settings";

i18n.fallbacks = true;
i18n.translations = { en, ar };
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const config = {
  animation: "timing",
  config: {
    stiffness: 1000,
    damping: 500,
    mass: 3,
    overshootClamping: true,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};
//router
function Router({ setUser, logOut, user }) {
  const [loading, setLoading] = useState(true);

  function showMessage() {
    alert("Session Expire");
  }
  useEffect(() => {
    setUser(
      logOut,
      () => {
        AsyncStorage.getItem("language").then((res) => {
          if (res) {
            i18n.locale = res;
          } else {
            i18n.locale = "en";
          }
          setLoading(false);
        });
      },
      showMessage
    );
  }, []);

  const DrawerStack = () => (
    <Drawer.Navigator
      initialRouteName="Dashboard"
      // apply for all screen
      screenOptions={{
        drawerStyle: { borderTopRightRadius: 30, borderBottomRightRadius: 30 },
        gestureEnabled: false,
        swipeEnabled: false,
        // drawerStyle: { borderBottomRightRadius: 30, borderTopRightRadius: 30 },
        transitionSpec: {
          open: TransitionSpecs.RevealFromBottomAndroidSpec,
          close: TransitionSpecs.FadeOutToBottomAndroidSpec,
        },
        drawerType: 'front'
      }}
      drawerContent={(props) => <CustomDrawer props={props} />}
    >
      <Stack.Screen
        name="Dashboard"
        component={DashboardStack}
        options={disableHeader}
      />
    </Drawer.Navigator>
  );


  const DashboardStack = () => (
    <Stack.Navigator
      initialRouteName="Splash"
      screenOptions={{
        gestureEnabled: false,
        swipeEnabled: false,
        transitionSpec: {
          open: TransitionSpecs.RevealFromBottomAndroidSpec,
          close: TransitionSpecs.FadeOutToBottomAndroidSpec,
        },
      }}
    >
      <Stack.Screen
        name="Dashboard"
        component={Dashbaord}
        options={disableHeader}
      />
      <Stack.Screen
        name="profile"
        component={Profile}
        options={disableHeader}
      />
      <Stack.Screen
        name="service"
        component={Service}
        options={disableHeader}
      />
      <Stack.Screen
        name="SellerProfile"
        component={SellerProfile}
        options={disableHeader}
      />
      <Stack.Screen name="vendor" component={Vendor} options={disableHeader} />
      <Stack.Screen
        name="CreateVehicle"
        component={(props) => <CreateVehicle {...props} goBack={true} />}
        options={disableHeader}
      />
      <Stack.Screen
        name="garageCategory"
        component={GarageCategory}
        options={disableHeader}
      />
      <Stack.Screen
        name="carListing"
        component={CarListing}
        options={disableHeader}
      />
      <Stack.Screen
        name="subCategories"
        component={SubCategories}
        options={disableHeader}
      />
      <Stack.Screen
        name="notfications"
        component={Notifications}
        options={disableHeader}
      />
      <Stack.Screen
        name="vendorDetails"
        component={vendorDetails}
        options={disableHeader}
      />
      <Stack.Screen
        name="Search"
        component={Search}
        // options={disableHeader}
        options={{
          headerShown: false,
          transitionSpec: {
            open: TransitionSpecs.RevealFromBottomAndroidSpec,
            close: TransitionSpecs.FadeOutToBottomAndroidSpec,
          },
          cardStyleInterpolator:
            CardStyleInterpolators.forScaleFromCenterAndroid,
        }}
      />
      <Stack.Screen
        name="Listings"
        component={Listings}
        options={{
          headerShown: false,
          transitionSpec: {
            open: TransitionSpecs.RevealFromBottomAndroidSpec,
            close: TransitionSpecs.FadeOutToBottomAndroidSpec,
          },
          cardStyleInterpolator:
            CardStyleInterpolators.forScaleFromCenterAndroid,
        }}
      />
      <Stack.Screen
        name="ListingDetails"
        component={ListingDetails}
        options={{
          headerShown: false,
          transitionSpec: {
            open: TransitionSpecs.RevealFromBottomAndroidSpec,
            close: TransitionSpecs.FadeOutToBottomAndroidSpec,
          },
          cardStyleInterpolator:
            CardStyleInterpolators.forScaleFromCenterAndroid,
        }}
      />
      <Stack.Screen
        name="VendorListings"
        component={VendorListings}
        options={{
          headerShown: false,
          transitionSpec: {
            open: TransitionSpecs.RevealFromBottomAndroidSpec,
            close: TransitionSpecs.FadeOutToBottomAndroidSpec,
          },
          cardStyleInterpolator:
            CardStyleInterpolators.forScaleFromCenterAndroid,
        }}
      />
      <Stack.Screen
        name="VehicleDetails"
        component={VehicleDetails}
        options={{
          headerShown: false,
          transitionSpec: {
            open: TransitionSpecs.RevealFromBottomAndroidSpec,
            close: TransitionSpecs.FadeOutToBottomAndroidSpec,
          },
          cardStyleInterpolator:
            CardStyleInterpolators.forScaleFromCenterAndroid,
        }}
      />
      <Stack.Screen
        name="garageDashboard"
        component={GarageDashboard}
        options={{
          headerShown: false,
          transitionSpec: {
            open: TransitionSpecs.RevealFromBottomAndroidSpec,
            close: TransitionSpecs.FadeOutToBottomAndroidSpec,
          },
          cardStyleInterpolator:
            CardStyleInterpolators.forScaleFromCenterAndroid,
        }}
      />
      <Stack.Screen
        name="settings"
        component={Settings}
        options={{
          headerShown: false,
          transitionSpec: {
            open: TransitionSpecs.RevealFromBottomAndroidSpec,
            close: TransitionSpecs.FadeOutToBottomAndroidSpec,
          },
          cardStyleInterpolator:
            CardStyleInterpolators.forScaleFromCenterAndroid,
        }}
      />
    </Stack.Navigator>
  );

  return (
    <NavigationContainer>
      {loading ? (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <ActivityIndicator size={30} color="black" />
        </View>
      ) : (
        <Stack.Navigator
          initialRouteName="Splash"
          screenOptions={{
            gestureEnabled: false,
            swipeEnabled: false,
            transitionSpec: {
              open: TransitionSpecs.RevealFromBottomAndroidSpec,
              close: TransitionSpecs.FadeOutToBottomAndroidSpec,
            },
          }}
        >
          {user.user ? (
            <>
              {user.user?.total_cars_count == 0 && user.user.type !== "seller" ? (
                <Stack.Screen
                  name="CreateVehicle"
                  component={CreateVehicle}
                  options={{
                    headerShown: false,
                    transitionSpec: {
                      open: TransitionSpecs.RevealFromBottomAndroidSpec,
                      close: TransitionSpecs.FadeOutToBottomAndroidSpec,
                    },
                  }}
                />
              ) : (
                <Stack.Screen
                  name="Dashboard"
                  component={DrawerStack}
                  options={{
                    headerShown: false,
                    transitionSpec: {
                      open: TransitionSpecs.RevealFromBottomAndroidSpec,
                      close: TransitionSpecs.FadeOutToBottomAndroidSpec,
                    },
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Stack.Screen
                name="Splash"
                component={Splash}
                options={{
                  headerShown: false,
                  transitionSpec: {
                    open: TransitionSpecs.RevealFromBottomAndroidSpec,
                    close: TransitionSpecs.FadeOutToBottomAndroidSpec,
                  },
                }}
              />
              <Stack.Screen
                name="Auth"
                component={Auth}
                options={{
                  headerShown: false,
                  transitionSpec: {
                    open: TransitionSpecs.RevealFromBottomAndroidSpec,
                    close: TransitionSpecs.FadeOutToBottomAndroidSpec,
                  },
                }}
              />
              <Stack.Screen
                name="login"
                component={Login}
                options={{
                  headerShown: false,
                  transitionSpec: {
                    open: TransitionSpecs.RevealFromBottomAndroidSpec,
                    close: TransitionSpecs.FadeOutToBottomAndroidSpec,
                  },
                }}
              />
              <Stack.Screen
                name="signUp"
                component={SignUp}
                options={{
                  headerShown: false,
                  transitionSpec: {
                    open: TransitionSpecs.RevealFromBottomAndroidSpec,
                    close: TransitionSpecs.FadeOutToBottomAndroidSpec,
                  },
                }}
              />
            </>
          )}
        </Stack.Navigator>
      )}
    </NavigationContainer>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, actions)(Router);

const disableHeader = { headerShown: false };
