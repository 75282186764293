import React, { useEffect, useCallback, useState } from "react";
import {
    Text,
    View,
    Image,
    ScrollView,
    Dimensions,
    Animated,
    TouchableOpacity,
    FlatList
} from "react-native";
import Header from "../components/Header";
import { connect } from "react-redux";
import * as actions from "../redux/actions"
import Loader from "../components/Loader"
import { image_url } from "../myConfig/api.json"
import CustomHeading from "../components/Heading";
import i18n from "i18n-js";
import CustomText from "../components/CustomText";

function Vendor(props) {
    const [listLoading, setListLoading] = useState(false)
    const [active, setActive] = useState({})


    useEffect(() => {
        setListLoading(true)
        // alert(JSON.stringify({
        //     'category_ids[]':props.route.params.category,
        //     'sub_category_ids[]':props.route.params.sub_category,
        //     'car_id':props.route.params.carId,
        // }))
        props.getVendors({
            'category_ids':props.route.params.category,
            'sub_category_id':props.route.params.sub_category,
            'car_id':props.route.params.carId,
        }).then(() => setListLoading(false))
    }, [])

    function renderItem({ item }) {
        // console.log(item.description)
        return (
            <TouchableOpacity
                onPress={() => props.navigation.push('vendorDetails', {id:item.id})}
                key={item.id} style={{
                    backgroundColor: 'white',
                    padding: 10,
                    shadowColor: "#000",
                    shadowOffset: {
                        width: 0,
                        height: 1,
                    },
                    shadowOpacity: 0.20,
                    shadowRadius: 1.41,
                    elevation: 2,
                    borderRadius: 15,
                    marginVertical: 5
                }}>
                <CustomText text={i18n.locale=="en"?item?.seller?.garage_name:item?.seller?.ar_garage_name}  />
                
                {/* <Text style={{ fontSize: 14, color: 'black', marginBottom: 10, textTransform: 'uppercase', marginLeft: 10, fontWeight: '700' }}>{i18n.locale=="en"?item.name:item?.ar_name}</Text> */}
                <View style={{ padding: 10, flexDirection: 'row', alignItems: 'flex-start' }}>
                    <View style={{ width: '30%' }}>
                        <Image
                            resizeMode="contain"
                            style={{ width: '100%', height: 60 }}
                            source={item.seller.logo ? { uri: `${image_url}/sellers/${item.seller.logo}` } : require('../assets/placeholder.jpeg')}
                        />
                    </View>
                    <View style={{ width: '70%', paddingHorizontal: 5 }}>
                        <CustomText
                        style={{fontSize:10,color:'gray'}}
                        text={`${i18n.t('LOGIN_TEXT2')}: ${item.email}`}
                        />
                        <CustomText
                        style={{fontSize:10,color:'gray'}}
                        text={`${i18n.t('SIGN_TEXT5')}: ${item.phone}`}
                        />
                        {/* {item.description && item.description!='null'?<Text style={{ fontSize: 12,color:'gray' }}>{item.description}</Text>:null} */}
                    </View>
                </View>
                <View>
                    {/* <ScrollView style={{marginTop:5}} horizontal={true} showsHorizontalScrollIndicator={false}>
            {item.sub_categories.map(subItem => (
              <TouchableOpacity 
              style={{alignItems:'center',justifyContent:'center',marginHorizontal:5,backgroundColor:'#f0f0ed',paddingVertical:5,borderRadius:10,paddingHorizontal:10}}>
                  <Image
                  style={{ 
                    width: 25, 
                    height: 25,
                    borderColor:'lightgray',
                    borderWidth:1,
                    borderRadius:20,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowOpacity: 0.20,
                    shadowRadius: 1.41,
                    elevation: 2,
                  }}
                  source={subItem.image ? { uri: `${image_url}/sub-categories/${subItem.image}` } : require('../assets/placeholder.jpeg')}
                  />
                <Text style={{fontSize:10,textAlign:'center',marginTop:2}}>{subItem.title}</Text>
              </TouchableOpacity>
            ))}
          </ScrollView> */}
                </View>
            </TouchableOpacity>
        )
    }


    return (
        <View style={{ flex: 1 }}>
            <Header
            round={true}
                disableSearch={true}
                disableDrawer={true}
                goBack={true}
                disableNotifications={true}
                navigation={props} />
            <ScrollView 
            contentContainerStyle={{width:'85%',alignSelf:'center'}}
            showsVerticalScrollIndicator={false} overScrollMode="never">
                <View style={{ }}>
                    <CustomHeading text={i18n.t('G2')} style={{marginTop:12}}/>
                    {/* <Text style={[styles.titleBlack, { marginTop: 12 }]}>
                        Vendors
                    </Text> */}

                    {listLoading ? <View style={{ height: 100, justifyContent: 'center', alignItems: 'center' }}><Loader /></View> : (
                        <FlatList
                            data={props.vendors.data}
                            renderItem={renderItem}
                            ListEmptyComponent={() => <Text style={{ textAlign: "center" }}>{i18n.t('NDF')}</Text>}
                            keyExtractor={(item) => item.id}
                        />
                    )}
                </View>
            </ScrollView>
        </View>
    );
}

function mapStateToProps({ userCars, categories, vendors }) {
    return { userCars, categories, vendors }
}
export default connect(mapStateToProps, actions)(Vendor)
const styles = {
    title: {
        fontSize: 25,
        fontWeight: "bold",
        color: "white",
    },
    whiteColor: {
        color: "white",
    },
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    body: {
        backgroundColor: "#f0f0f0",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        padding: 12,
        flex: 1,
        marginTop: -10,
    },
    card: {
        flex: 1,
        height: 100,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 12,
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
    },
    cardTitle: {
        fontSize: 12,
        marginTop: 8,
        textAlign: "center"
    },
    cardBody: {
        fontColor: "gray",
    },
};
