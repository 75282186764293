import { GET_GARAGE_LISTING } from "../actions/types";

const initialState = []

export default function garageListing(state = initialState, action) {
    switch (action.type) {
        case GET_GARAGE_LISTING:
            return action.payload;
        default:
            return state;
    }
}