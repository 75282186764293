import React from "react";
import { useState, useCallback } from "react";
import { useEffect, useRef } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  Animated,
  Dimensions,
  FlatList
} from "react-native";
import Header from "../components/Header";
import { FontAwesome } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import CustomButton from "../components/CustomButton";
import { TextInput } from "react-native-paper";
import { Searchbar } from "react-native-paper";
import { image_url } from "../myConfig/api.json"
import removeTags from "../utils/removeTags"
import CustomText from "./CustomText";
import i18n from "i18n-js";
export default function ListingCard(props) {
  // console.log('item',props.item)
  const [items, setItems] = useState([]);
  const [currentItem, setCurrentItem] = useState(0);
  const [fadeAnimation, seFadeAnimation] = useState(new Animated.Value(1));
  const imageRef = useRef();
  useEffect(() => {
    setCurrentItem(props.item.images[0])
  }, []);
  const invoke = (change) => {
    // console.log(change);
    if (change === "dragging") {
      fadeOut();
    }
    if (change === "settling") {
      fadeIn();
    }
  };

  const fadeIn = () => {
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: 1000,
    }).start();
  };

  const fadeOut = () => {
    Animated.timing(fadeAnimation, {
      toValue: 0,
      duration: 200,
    }).start();
  };

  // const handleScroll = (e) => {
  //   const positionX = e.nativeEvent.contentOffset.x;
  //   const positionY = e.nativeEvent.contentOffset.y;
  //   const layout = e.nativeEvent;
  //   const size = layout.contentSize.width;
  //   const oneItem = size / items.length;
  //   //console.log(positionX, oneItem, positionX / oneItem)
  //   setCurrentItem(Math.round(positionX / oneItem));
  // };

  const getItemLayout = (data, index) => {
    return {
      length: Dimensions.get('screen').width - (24 + 24),
      offset: (Dimensions.get('screen').width - (24 + 24)) * index,
      index,
    };
  };

  const onChangeItem = useCallback(({ viewableItems, changed }) => {
    if (viewableItems[0]?.item) {
      setCurrentItem(viewableItems[0]?.item);
    }
  }, [])

  // console.log(props.item)
  return (
    <TouchableOpacity
      onPress={() => props.props.navigation.navigate("ListingDetails", { id: props.item.id, sellerList: props.sellerList })}
      style={[styles.listingCard, props.style, { width: props.vertical ? '85%' : 230, height: props.vertical ? 155 : 140 }]}>

      <Image
        source={props.item.main_image ? { uri: `${image_url}/listings/${props.item.main_image}` } : require('../assets/placeholder.jpeg')}
        // source={require('../assets/placeholder.jpeg')}
        style={{ height: props.vertical ? 115 : 103, width: props.vertical ? '100%' : 230, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
        resizeMode="cover"
      />
      {/* <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5 }}>
        {props.item.images.map((item, i) => (
          <View
            style={{
              backgroundColor: item.id == currentItem.id ? "black" : "gray",
              height: 10,
              width: 10,
              marginLeft: 6,
              borderRadius: 5,
            }}
          />
        ))}
      </View> */}
      <Animated.View
        style={[
          {
            opacity: fadeAnimation,
          },
        ]}
      >

        <View style={{ paddingHorizontal: 10 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 3 }}>
            {
              i18n.locale == "en" ? (
                <>
                  <View>
                    <CustomText style={{ fontSize: 12, textTransform: 'capitalize' }} text={i18n.locale == 'en' ? (props.item?.title?.length > 15 ? props.item?.title?.slice(0, 15) + "..." : props.item?.title) : (props.item?.ar_title?.length > 15 ? props.item?.ar_title?.slice(0, 15) + "..." : props.item?.ar_title)} />
                  </View>
                  <View>
                    <CustomText style={{ fontSize: 11, color: 'gray' }} text={props.item.price + " AED"} />
                  </View>
                </>
              ) : (
                <>
                  <View>
                    <CustomText style={{ fontSize: 11, color: 'gray' }} text={props.item.price + " AED"} />
                  </View>
                  <View>
                    <CustomText style={{ fontSize: 12, textTransform: 'capitalize' }} text={i18n.locale == 'en' ? (props.item?.title?.length > 15 ? props.item?.title?.slice(0, 15) + "..." : props.item?.title) : (props.item?.ar_title?.length > 15 ? props.item?.ar_title?.slice(0, 15) + "..." : props.item?.ar_title)} />
                  </View>
                </>
              )
            }
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            {
              i18n.locale == "en" ? (
                <>
                  <View>
                    <CustomText style={{ fontSize: 10, textTransform: 'capitalize', color: 'gray' }} text={i18n.locale == "en" ? props.item.garage_name : props.item.ar_garage_name} />
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <FontAwesome name="star" size={9} color="black" />
                    <View>
                      <CustomText style={{ fontSize: 10, color: 'gray', marginLeft: 2 }} text={props.item.average_ratings} />
                    </View>
                  </View>
                </>
              ) : (
                <>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <FontAwesome name="star" size={9} color="black" />
                    <View>
                      <CustomText style={{ fontSize: 10, color: 'gray', marginLeft: 2 }} text={props.item.average_ratings} />
                    </View>
                  </View>
                  <View>
                    <CustomText style={{ fontSize: 10, textTransform: 'capitalize', color: 'gray' }} text={i18n.locale == "en" ? props.item.garage_name : props.item.ar_garage_name} />
                  </View>
                </>
              )
            }
          </View>
        </View>
        {/* <Text style={styles.listingTitle}>{props.item.title}</Text>
          <Text style={styles.listingDesc}>{removeTags(props.item.description)}</Text>
          <Text style={styles.listingOffer}>Starting @ ${props.item.price}</Text> */}
      </Animated.View>
    </TouchableOpacity>
  );
}

const styles = {
  listingCard: {
    height: 140,
    backgroundColor: "white",
    borderRadius: 10,
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
  },
  sliderContainer: {
    height: 170,
    backgroundColor: "#00000000",
  },
  listingTitle: {
    fontSize: 18,
    marginTop: 12,
  },
  listingOffer: {
    fontSize: 20,
    marginTop: 12,
  },
  listingDesc: {
    color: "gray",
  },
};
