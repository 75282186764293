import { GET_LISTING_DETAIL } from "../actions/types";

const initialState={}
export default function listingDetails(state = initialState, action) {
    switch (action.type) {
      case GET_LISTING_DETAIL:
        return action.payload;
      default:
        return state;
    }
  }