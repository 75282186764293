import { GET_CARS } from "../actions/types";

const initialState=[]
export default function cars(state = initialState, action) {
    switch (action.type) {
      case GET_CARS:
        return action.payload;
      default:
        return state;
    }
  }