import {combineReducers,compose,createStore,applyMiddleware} from "redux";
import ReduxThunk from "redux-thunk";
import user from "./reducers/user"
import userCars from "./reducers/userCars";
import cars from "./reducers/cars";
import categories from "./reducers/categories";
import subCategories from "./reducers/subCategories";
import subSubCategories from "./reducers/subSubCategories";
import feature from "./reducers/feature";
import vendors from "./reducers/vendors";
import vendorDetails from "./reducers/vendorDetails";
import list from "./reducers/list";
import carListing from "./reducers/carListing";
import searchHistory from "./reducers/searchHistory";
import notifications from "./reducers/notifications";
import vendorListing from "./reducers/vendorListing";
import listingDetails from "./reducers/listingDetails";
import reviews from "./reducers/reviews";
import types from "./reducers/types";
import models from "./reducers/models";
import transmissions from "./reducers/transmissions";
import years from "./reducers/years";
import blocks from "./reducers/blocks";
import garageListing from "./reducers/garageListing";
const reducers =combineReducers({
    user,
    userCars,
    cars,
    categories,
    subCategories,
    subSubCategories,
    feature,
    vendors,
    vendorDetails,
    list,
    carListing,
    searchHistory,
    notifications,
    vendorListing,
    listingDetails,
    reviews,
    types,
    models,
    transmissions,
    years,
    blocks,
    garageListing
})


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store =createStore(reducers,{},composeEnhancers(applyMiddleware(ReduxThunk)));


export default store