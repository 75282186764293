import { SEARCH_LIST } from "../actions/types";

const initialState={}

export default function list(state = initialState, action) {
    switch (action.type) {
      case SEARCH_LIST:
        return action.payload;
      default:
        return state;
    }
  }