import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Text, View, Image, ScrollView } from "react-native";
import Header from "../components/Header";
import { FontAwesome } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import CustomButton from "../components/CustomButton";
import { TextInput } from "react-native-paper";

export default function VehicleDetails(props) {
  const checkAndRedirect = () => props.navigation.navigate("Dashboard");
  // console.log(props.route.params)
  return (
    <View style={{ flex: 1 }}>
      <Header
        navigation={props}
        disableSearch={true}
        disableDrawer={true}
        disableNotifications={true}
        goBack={true}
      />
      <View style={{ flex: 1, padding: 12 }}>
        <ScrollView>
          {/* BRAND NAMES */}

          <View style={styles.brandCard}>
            <Image
              source={require("../assets/cars/mers-logo.png")}
              style={{ height: 60 }}
              resizeMode="contain"
            />
            <Text style={styles.brandTitle}>Honda Civic</Text>
          </View>

          {/* VEHICLE DETAILS INPUTS */}

          <TextInput
            label="License Plate"
            value={props.route.params?.registration_number}
            editable={false}
            activeUnderlineColor="black"
            style={styles.inputStyles}
          />
          <TextInput
            label="Registeration Year"
            value={props.route.params?.registration_year}
            editable={false}
            activeUnderlineColor="black"
            style={styles.inputStyles}
          />
          <TextInput
            label="Transmission"
            value={props.route.params?.transmission}
            editable={false}
            activeUnderlineColor="black"
            style={styles.inputStyles}
          />
          {/* <TextInput
            label="Mileage (KM)"
            placeholder="14000"
            activeUnderlineColor="black"
            value="123213"
            editable={false}
            style={styles.inputStyles}
          />
          <TextInput
            label="Owner"
            value="First"
            editable={false}
            activeUnderlineColor="black"
            style={styles.inputStyles}
          /> */}
        </ScrollView>
      </View>
    </View>
  );
}

const styles = {
  title: {
    fontSize: 25,
    fontWeight: "bold",
    color: "black",
    marginBottom: 12,
  },
  whiteColor: {
    color: "white",
  },
  titleBlack: {
    fontSize: 25,
    fontWeight: "bold",
    color: "black",
  },
  body: {
    backgroundColor: "#f0f0f0",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    padding: 12,
    flex: 1,
    marginTop: -20,
  },
  card: {
    width: 250,
    height: 150,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 10,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: 25,
    marginTop: 12,
  },
  cardBody: {
    fontColor: "gray",
  },
  brandCard: {
    height: 150,
    width: 120,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 12,
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
    alignSelf:'center'
  },
  brandTitle: {
    marginTop: 16,
  },
  inputStyles: { marginTop: 12, backgroundColor: "#f0f0f0" },
};
