import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
// import { Text, View, Image, ScrollView, TouchableOpacity } from "react-native";
import { Text, View, Image, TouchableOpacity, Platform } from "react-native";
import Header from "../components/Header";
import { FontAwesome } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import CustomButton from "../components/CustomButton";
import { Searchbar } from "react-native-paper";
import * as actions from "../redux/actions"
import { connect } from "react-redux";
import { image_url } from "../myConfig/api.json"
import Loader from "../components/Loader";
import ListingCard from "../components/ListingCard";
import Notfound from "../components/Notfound"
import i18n from "i18n-js";
import TextInput from "../components/TextInput"
import CustomHeading from "../components/Heading";
import CustomText from "../components/CustomText";
import { FormControl, Select, CheckIcon, FlatList } from "native-base";
import { ScrollView } from 'react-native-gesture-handler';

const THUMB_RADIUS = 12;
function Search(props) {

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(false)
  const [fields, setFields] = useState({
    keyword: "",
    category: "",
    subCategory: "",
    subSubCategory: "",
    type: "",
    make: "",
    model: "",
    variant: "",
    city: "",
    sortBy: "",
    priceStart: 1,
    priceEnd: 10000,
    rating: ""
  })
  useEffect(() => {
    setLoading(true)
    Promise.all([props.getCategories(), props.getCars()])
      .then(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (fields.category) {
      props.getSubCategories(fields.category)
    }
  }, [fields.category])

  useEffect(() => {
    if (fields.subCategory) {
      props.getSubSubCategories(fields.subCategory)
    }
  }, [fields.subCategory])

  useEffect(() => {
    if (fields.make) {
      props.getTypes(fields.make)
      setFields({ ...fields, type: "", model: "", variant: "" })
      // props.getModels(fields.make)
    }
  }, [fields.make])

  useEffect(() => {
    if (fields.type) {
      console.log("Sdfsadf", fields.make, fields.type)
      props.getModels(fields.make, fields.type)
      setFields({ ...fields, model: "", variant: "" })
    }
  }, [fields.type])

  useEffect(() => {
    if (fields.model) {
      props.getYears(fields.model)
      setFields({ ...fields, variant: "" })
    }
  }, [fields.model])

  useEffect(() => {
    if (search) {
      setLoading(true)
      const payload = {
        ["categories[]"]: fields.category,
        ["sub_categories[]"]: fields.subCategory,
        ["sub_sub_categories[]"]: fields.subSubCategory,
        ["makes[]"]: fields.make,
        "variants[]": fields.variant,
        min_price: fields.priceStart,
        max_price: fields.priceEnd,
        ["models[]"]: fields.model,
        city: fields.city,
        sort: fields.sortBy,
        search: fields.keyword,
        ratings: fields.rating,
        seller_id: (props.route?.params?.id) ? (props.route?.params?.id) : undefined,
      }
      // alert(JSON.stringify(payload))
      props.searchList(payload).then(() => { console.warn(payload); setLoading(false) })
    }
  }, [search, fields])


  const renderThumb = useCallback(() => <View style={styles.root} />, [])
  const renderRailSelected = useCallback(() => <View style={styles.root2} />, [])
  const renderRail = useCallback(() => <View style={styles.root3} />, [])
  const renderNotch = useCallback(() => <View style={styles.root4} />, [])
  const renderLabel = useCallback((value) => (
    <View style={styles.root6}>
      <Text style={styles.text}>{value}</Text>
    </View>
  ), [])

  const handleValueChange = useCallback((low, high) => {
    setFields({ priceStart: low, priceEnd: high });
  }, []);

  function renderSlider() {
    if (Platform.OS == "web") {
      const RangeSliderWeb = require('react-range-slider-input').default
      // require('react-range-slider-input/dist/style.css')
      return (
        <RangeSliderWeb min={1} max={1000} step={1}
        value={[fields.priceStart,fields.priceEnd]}
        onInput={(e)=>{
          setFields({ priceStart: e[0], priceEnd: e[1] });
        }}
        />
      )
    } else {
      const RangeSlider = require('rn-range-slider').default
      return (
        <RangeSlider
          style={{ marginVertical: 10 }}
          min={1}
          max={10000}
          step={1}
          floatingLabel
          renderThumb={renderThumb}
          renderRail={renderRail}
          renderRailSelected={renderRailSelected}
          renderLabel={renderLabel}
          renderNotch={renderNotch}
          onTouchEnd={handleValueChange}
        />
      )
    }
  }
  const renderFilter = () => {
    return (
      <ScrollView contentContainerStyle={{ width: '85%', alignSelf: 'center' }}>
        <TextInput
          value={fields.keyword}
          onChangeText={(v) => setFields({ ...fields, keyword: v })}
          placeholder={i18n.t('S1')}
          center={true}
          style={Platform.OS=="web"?{
            marginVertical: 5,
            marginTop: 10,
            borderColor: 'lightgray',
            borderWidth: 1,
          }:{
            marginVertical: 5,
            marginTop: 10,
            borderColor: 'lightgray',
            borderWidth: 1,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 2.62,

            elevation: 4,
          }}
        />
        <CustomHeading text={i18n.t('S2')} style={{ marginTop: 7.5, marginBottom: 5, textAlign: i18n.locale == "en" ? "left" : "right" }} />
        <CustomText style={{ marginVertical: 5, textAlign: i18n.locale == "en" ? "left" : "right" }} text={i18n.t('DASH_TEXT1')} />
        <FormControl>
          <Select
            selectedValue={Number(fields.category)}
            onValueChange={(v) => setFields({ ...fields, category: v })}
            accessibilityLabel={i18n.t('S3')}
            placeholder={i18n.t('S3')}
            placeholderTextColor={"black"}
            // fontWeight="bold"
            textAlign={i18n.locale == "en" ? "left" : "right"}
            style={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }}
            fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
            marginY={0.35}
            fontSize={14}
            borderRadius={30}
            backgroundColor="white"
            height={39.9}
            shadow={Platform.OS=="web"?"none":"1"}
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5}
              />
            }}>
            {props.categories.map((item) => (
              <Select.Item
                _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }}
                style={{ fontFamily: "Poppins-Regular" }}
                key={item.id}
                label={i18n.locale == "en" ? item.title : item.ar_title}
                value={item.id}
              />
            ))}
          </Select>
        </FormControl>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ width: '45%' }}>
            <FormControl>
              <Select
                isDisabled={!fields.category}
                selectedValue={Number(fields.subCategory)}
                onValueChange={(v) => setFields({ ...fields, subCategory: v })}
                accessibilityLabel={i18n.t('S5')}
                placeholder={i18n.t('S5')}
                placeholderTextColor={"black"}
                fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                textAlign={i18n.locale == "en" ? "left" : "right"}
                style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light' }}
                marginY={0.35}
                fontSize={14}
                borderRadius={30}
                backgroundColor="white"
                height={39.9}
                shadow={Platform.OS=="web"?"none":"1"}
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size={5}
                  />
                }}>
                {props.subCategories.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
              </Select>
            </FormControl>
          </View>
          <View style={{ width: '45%' }}>
            <FormControl>
              <Select
                isDisabled={!fields.subCategory}
                selectedValue={Number(fields.subSubCategory)}
                onValueChange={(v) => setFields({ ...fields, subSubCategory: v })}
                accessibilityLabel={i18n.t('S6')}
                placeholder={i18n.t('S6')}
                placeholderTextColor={"black"}
                fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                textAlign={i18n.locale == "en" ? "left" : "right"}
                style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light' }}
                marginY={0.35}
                fontSize={14}
                borderRadius={30}
                backgroundColor="white"
                height={39.9}
                shadow={Platform.OS=="web"?"none":"1"}
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size={5}
                  />
                }}>
                {props.subSubCategories.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
              </Select>
            </FormControl>
          </View>
        </View>
        <CustomText style={{ marginVertical: 5, textAlign: i18n.locale == "en" ? "left" : "right" }} text={i18n.t('S11')} />
        <FormControl>
          <Select
            selectedValue={Number(fields.make)}
            onValueChange={(v) => setFields({ ...fields, make: v })}
            accessibilityLabel={i18n.t('S12')}
            placeholder={i18n.t('S12')}
            placeholderTextColor={"black"}
            textAlign={i18n.locale == "en" ? "left" : "right"}
            style={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }}
            marginY={0.35}
            fontSize={14}
            fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
            borderRadius={30}
            backgroundColor="white"
            height={39.9}
            shadow={Platform.OS=="web"?"none":"1"}
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5}
              />
            }}>
            {props.cars.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
          </Select>
        </FormControl>
        <FormControl>
          <Select
            isDisabled={!(fields.make)}
            selectedValue={Number(fields.type)}
            onValueChange={(v) => setFields({ ...fields, type: v })}
            accessibilityLabel={i18n.t('CAR_D_TYPE')}
            placeholder={i18n.t('CAR_D_TYPE')}
            placeholderTextColor={"black"}
            textAlign={i18n.locale == "en" ? "left" : "right"}
            style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light' }}
            fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
            marginY={0.35}
            fontSize={14}
            borderRadius={30}
            backgroundColor="white"
            height={39.9}
            shadow={Platform.OS=="web"?"none":"1"}
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5}
              />
            }}>
            {props.types.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
          </Select>
        </FormControl>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ width: '45%' }}>
            <FormControl>
              <Select
                isDisabled={!(fields.type)}
                selectedValue={Number(fields.model)}
                onValueChange={(v) => setFields({ ...fields, model: v })}
                accessibilityLabel={i18n.t('S5')}
                placeholder={i18n.t('S5')}
                placeholderTextColor={"black"}
                textAlign={i18n.locale == "en" ? "left" : "right"}
                fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light' }}
                marginY={0.35}
                fontSize={14}
                borderRadius={30}
                backgroundColor="white"
                height={39.9}
                shadow={Platform.OS=="web"?"none":"1"}
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size={5}
                  />
                }}>
                {props.models.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
              </Select>
            </FormControl>
          </View>
          <View style={{ width: '45%' }}>
            <FormControl>
              <Select
                isDisabled={!fields.model}
                selectedValue={Number(fields.variant)}
                onValueChange={(v) => setFields({ ...fields, variant: v })}
                accessibilityLabel={i18n.t('S6')}
                placeholder={i18n.t('S6')}
                placeholderTextColor={"black"}
                textAlign={i18n.locale == "en" ? "left" : "right"}
                fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light' }}
                marginY={0.35}
                fontSize={14}
                borderRadius={30}
                backgroundColor="white"
                height={39.9}
                shadow={Platform.OS=="web"?"none":"1"}
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size={5}
                  />
                }}>
                {props.years.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
              </Select>
            </FormControl>
          </View>
        </View>
        <CustomText style={{ marginVertical: 5, textAlign: i18n.locale == "en" ? "left" : "right" }} text={i18n.t('S13')} />
        <FormControl>
          <Select
            selectedValue={fields.city}
            onValueChange={(v) => setFields({ ...fields, city: v })}
            accessibilityLabel={i18n.t('S3')}
            placeholder={i18n.t('S14')}
            placeholderTextColor={"black"}
            textAlign={i18n.locale == "en" ? "left" : "right"}
            style={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }}
            fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
            marginY={0.35}
            fontSize={14}
            borderRadius={30}
            backgroundColor="white"
            height={39.9}
            shadow={Platform.OS=="web"?"none":"1"}
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5}
              />
            }}>
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY1')} label={i18n.t('CITY1')} value={"Abu Dhabi"} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY2')} label={i18n.t('CITY2')} value={"Al Ain"} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY3')} label={i18n.t('CITY3')} value={"Ajman"} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY4')} label={i18n.t('CITY4')} value={"Dubai"} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY5')} label={i18n.t('CITY5')} value={"Fujairah"} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY6')} label={i18n.t('CITY6')} value={"Sharjah"} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('CITY7')} label={i18n.t('CITY7')} value={"Ras Al Khaimah"} />
          </Select>
        </FormControl>
        <FormControl>
          <Select
            selectedValue={fields.sortBy}
            onValueChange={(v) => setFields({ ...fields, sortBy: v })}
            accessibilityLabel={i18n.t('S15')}
            placeholder={i18n.t('S15')}
            placeholderTextColor={"black"}
            textAlign={i18n.locale == "en" ? "left" : "right"}
            style={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }}
            fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
            marginY={0.35}
            fontSize={14}
            borderRadius={30}
            backgroundColor="white"
            height={39.9}
            shadow={Platform.OS=="web"?"none":"1"}
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5}
              />
            }}>
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('S18')} label={i18n.t('S18')} value="Oldest" />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={i18n.t('S19')} label={i18n.t('S19')} value="Newest" />

          </Select>
        </FormControl>
        <View
          style={Platform.OS=="web"?{
            padding: 10,
            marginVertical: 2,
            backgroundColor: 'white',
            borderRadius: 20,
            borderColor: 'lightgray',
            borderWidth: 1
          }:{
            padding: 10,
            marginVertical: 2,
            backgroundColor: 'white',
            borderRadius: 20,
            borderColor: 'lightgray',
            borderWidth: 1,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.23,
            shadowRadius: 2.62,

            elevation: 4,
          }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <CustomText text={i18n.t('S10')} />
            <CustomText style={{ color: "gray" }} text={`AED  ${fields.priceStart} - AED  ${fields.priceEnd}`} />
          </View>
          {renderSlider()}
        </View>
        <FormControl>
          <Select
            selectedValue={fields.rating}
            onValueChange={(v) => setFields({ ...fields, rating: v })}
            accessibilityLabel={i18n.t('VIEW_LIST_TEXT5')}
            placeholder={i18n.t('VIEW_LIST_TEXT5')}
            placeholderTextColor={"black"}
            textAlign={i18n.locale == "en" ? "left" : "right"}
            style={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }}
            marginY={0.35}
            fontSize={14}
            borderRadius={30}
            backgroundColor="white"
            fontFamily={i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold"}
            height={39.9}
            shadow={Platform.OS=="web"?"none":"1"}
            _selectedItem={{
              bg: "teal.600",
              endIcon: <CheckIcon size={5}
              />
            }}>
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={5.0} label={"5.0"} value={5.0} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={4.5} label={"4.5"} value={4.5} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={4.0} label={"4.0"} value={4.0} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={3.5} label={"3.5"} value={3.5} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={3.0} label={"3.0"} value={3.0} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={2.5} label={"2.5"} value={2.5} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={2.0} label={"2.0"} value={2.0} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={1.5} label={"1.5"} value={1.5} />
            <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Medium" : "GE-SS-Unique-Bold" }} key={1.0} label={"1.0"} value={1.0} />

          </Select>
        </FormControl>
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginVertical: 10 }}>
          <TouchableOpacity
            onPress={() => {
              setFields({
                keyword: "",
                category: "",
                subCategory: "",
                subSubCategory: "",
                type: "",
                make: "",
                model: "",
                variant: "",
                city: "",
                sortBy: "",
                priceStart: "",
                priceEnd: "",
                rating: ""
              })
            }}
            style={{
              backgroundColor: 'white',
              width: 95,
              height: 35,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 20,
              borderWidth: 1,
              borderColor: 'lightgray',
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.23,
              shadowRadius: 2.62,
              elevation: 4,
            }}
          >
            <CustomText style={{ color: 'lightgray',textAlign:'center' }} text={i18n.t('S16')} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setSearch(true)}
            style={{
              backgroundColor: 'black',
              width: 170,
              height: 35,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 20,
              borderWidth: 1,
              borderColor: 'black',
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.23,
              shadowRadius: 2.62,
              elevation: 4,
            }}
          >
            <CustomText style={{ color: 'white',textAlign:'center' }} text={i18n.t('SE1')} />
          </TouchableOpacity>
        </View>
      </ScrollView>
    )
  }
  const renderList = () => {
    return (
      <View>
        <FlatList
          data={props.list.data}
          keyExtractor={(item, i) => i.toString()}
          showsVerticalScrollIndicator={false}
          ListEmptyComponent={Notfound}
          renderItem={({ item }) => <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}><ListingCard vertical={true} item={item} props={props} style={{ marginTop: 12 }} /></View>}
        />
      </View>
    )
  }
  if (loading) {
    return <Loader />
  }
  return (
    <View style={{ flex: 1 }}>
      <Header
        goBack={true}
        props={props}
        round={true}
        disableSearch={true}
        disableDrawer={true}
        disableNotifications={true} />
      {search && (<View>
        <ScrollView contentContainerStyle={{ flexGrow: 1, paddingTop: 20, paddingBottom: 20, alignSelf: 'center' }} horizontal={true} showsHorizontalScrollIndicator={false}>
          <View style={{ flexDirection: 'row', marginTop: 10 }}>

            <TouchableOpacity
              onPress={() => setSearch(false)}
              style={{
                height: 40,
                width: 40,
                borderRadius: 20,
                backgroundColor: 'white',
                marginHorizontal: 5,
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: 'lightgray',
                borderWidth: 1,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.23,
                shadowRadius: 2.62,

                elevation: 4,
              }}>
              <FontAwesome name="filter" size={24} color="black" />
            </TouchableOpacity>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  selectedValue={Number(fields.category)}
                  onValueChange={(v) => setFields({ ...fields, category: v })}
                  accessibilityLabel={i18n.t('S3')}
                  placeholder={i18n.t('S3')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  borderRadius={30}
                  minWidth={160}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {props.categories.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            </View>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  isDisabled={!fields.category}
                  selectedValue={Number(fields.subCategory)}
                  onValueChange={(v) => setFields({ ...fields, subCategory: v })}
                  accessibilityLabel={i18n.t('S5')}
                  placeholder={i18n.t('S5')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  borderRadius={30}
                  minWidth={90}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {props.subCategories.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            </View>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  isDisabled={!fields.subCategory}
                  selectedValue={Number(fields.subSubCategory)}
                  onValueChange={(v) => setFields({ ...fields, subSubCategory: v })}
                  accessibilityLabel={i18n.t('S6')}
                  placeholder={i18n.t('S6')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  borderRadius={30}
                  minWidth={120}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {props.subSubCategories.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            </View>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  selectedValue={Number(fields.make)}
                  onValueChange={(v) => setFields({ ...fields, make: v })}
                  accessibilityLabel={i18n.t('S12')}
                  placeholder={i18n.t('S12')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  borderRadius={30}
                  minWidth={100}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {props.cars.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            </View>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  isDisabled={!fields.make}
                  selectedValue={Number(fields.type)}
                  onValueChange={(v) => setFields({ ...fields, type: v })}
                  accessibilityLabel={i18n.t('CAR_D_TYPE')}
                  placeholder={i18n.t('CAR_D_TYPE')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  borderRadius={30}
                  backgroundColor="white"
                  minWidth={100}
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {props.types.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            </View>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  isDisabled={!fields.type}
                  selectedValue={Number(fields.model)}
                  onValueChange={(v) => setFields({ ...fields, model: v })}
                  accessibilityLabel={i18n.t('S5')}
                  placeholder={i18n.t('S5')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  minWidth={90}
                  borderRadius={30}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {props.models.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            </View>


            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  isDisabled={!fields.model}
                  selectedValue={Number(fields.variant)}
                  onValueChange={(v) => setFields({ ...fields, variant: v })}
                  accessibilityLabel={i18n.t('S6')}
                  placeholder={i18n.t('S6')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  borderRadius={30}
                  minWidth={120}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  {props.years.map((item) => <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={item.id} label={i18n.locale == "en" ? item.title : item.ar_title} value={item.id} />)}
                </Select>
              </FormControl>
            </View>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  selectedValue={fields.city}
                  onValueChange={(v) => setFields({ ...fields, city: v })}
                  accessibilityLabel={i18n.t('S3')}
                  placeholder={i18n.t('S14')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  borderRadius={30}
                  backgroundColor="white"
                  height={39.9}
                  minWidth={90}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('CITY1')} label={i18n.t('CITY1')} value='Abu Dhabi' />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('CITY2')} label={i18n.t('CITY2')} value="Al Ain" />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('CITY3')} label={i18n.t('CITY3')} value="Ajman" />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('CITY4')} label={i18n.t('CITY4')} value="Dubai" />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('CITY5')} label={i18n.t('CITY5')} value="Fujairah" />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('CITY6')} label={i18n.t('CITY6')} value="Sharjah" />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('CITY7')} label={i18n.t('CITY7')} value="Ras Al Khaimah" />
                </Select>
              </FormControl>
            </View>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  selectedValue={fields.sortBy}
                  onValueChange={(v) => setFields({ ...fields, sortBy: v })}
                  accessibilityLabel={i18n.t('S15')}
                  placeholder={i18n.t('S15')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  minWidth={110}
                  fontSize={14}
                  borderRadius={30}
                  backgroundColor="white"
                  height={39.9}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('S18')} label={i18n.t('S18')} value='Oldest' />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={i18n.t('S19')} label={i18n.t('S19')} value='Newest' />

                </Select>
              </FormControl>
            </View>

            <View style={{ marginHorizontal: 5 }}>
              <FormControl>
                <Select
                  selectedValue={fields.rating}
                  onValueChange={(v) => {
                    setFields({ ...fields, rating: v })
                  }}
                  accessibilityLabel={i18n.t('VIEW_LIST_TEXT5')}
                  placeholder={i18n.t('VIEW_LIST_TEXT5')}
                  placeholderTextColor={"black"}
                  style={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light',height:39.9 }}
                  fontFamily={i18n.locale == "en" ? "Poppins-Regular" : 'GE-SS-Unique-Light'}
                  marginY={0.35}
                  fontSize={14}
                  borderRadius={30}
                  backgroundColor="white"
                  height={39.9}
                  minWidth={100}
                  shadow={Platform.OS=="web"?"none":"1"}
                  _selectedItem={{
                    bg: "teal.600",
                    endIcon: <CheckIcon size={5}
                    />
                  }}>
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={5.0} label={"5.0"} value={5.0} />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={4.5} label={"4.5"} value={4.5} />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={4.0} label={"4.0"} value={4.0} />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={3.5} label={"3.5"} value={3.5} />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={3.0} label={"3.0"} value={3.0} />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={2.5} label={"2.5"} value={2.5} />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={2.0} label={"2.0"} value={2.0} />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={1.5} label={"1.5"} value={1.5} />
                  <Select.Item _text={{ fontFamily: i18n.locale == "en" ? "Poppins-Regular" : "GE-SS-Unique-Light" }} key={1.0} label={"1.0"} value={1.0} />

                </Select>
              </FormControl>
            </View>
          </View>
        </ScrollView>
      </View>)}
      <View style={{ width: '100%', alignSelf: 'center', flex: 1 }}>
        {(search || props.route?.params?.second) ? renderList() : renderFilter()}
      </View>
    </View>
  );
}

function mapStateToProps({ categories, subCategories, subSubCategories, types, cars, models, years, list }) {
  return { categories, subCategories, subSubCategories, types, cars, models, years, list }
}

export default connect(mapStateToProps, actions)(Search)

const styles = {
  title: {
    fontSize: 25,
    fontWeight: "bold",
    color: "black",
    marginBottom: 12,
  },
  whiteColor: {
    color: "white",
  },
  titleBlack: {
    fontSize: 25,
    fontWeight: "bold",
    color: "black",
  },
  body: {
    backgroundColor: "#f0f0f0",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    padding: 12,
    flex: 1,
    marginTop: -20,
  },
  card: {
    width: 250,
    height: 150,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 10,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: {
    fontSize: 25,
    marginTop: 12,
  },
  cardBody: {
    fontColor: "gray",
  },
  brandCard: {
    height: 110,
    width: 100,
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
    marginHorizontal: 5
  },
  brandTitle: {
    marginTop: 16,
  },
  filterCard: {
    height: 45,
    padding: 12,
    margin: 8,
    marginTop: 0,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 12,
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
  },
  servicesCard: {
    height: 100,
    padding: 12,
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 12,
    flexDirection: "row",
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
  },
  serviceTitle: {
    fontWeight: "bold",
  },
  serviceDescription: {
    marginTop: 6,
  },
  roundedFilters: {
    padding: 12,
    borderRadius: 20,
    borderWidth: 1,
    marginLeft: 6,
  },
  root: {
    width: THUMB_RADIUS * 2,
    height: THUMB_RADIUS * 2,
    borderRadius: THUMB_RADIUS,
    borderWidth: 2,
    borderColor: "#B9C7DB",
    backgroundColor: '#B9C7DB',
  },
  root2: {
    height: 4,
    backgroundColor: '#B9C7DB',
    borderRadius: 2,
  },
  root3: {
    flex: 1,
    height: 4,
    borderRadius: 2,
    backgroundColor: '#7f7f7f',
  },
  root4: {
    width: 8,
    height: 8,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: '#B9C7DB',
    borderLeftWidth: 4,
    borderRightWidth: 4,
    borderTopWidth: 8,
  },
  root5: {
    alignItems: 'center',
    padding: 8,
    backgroundColor: '#4499ff',
    borderRadius: 4,
  },
  text5: {
    fontSize: 16,
    color: '#fff',
  },
  root6: {
    alignItems: 'center',
    padding: 8,
    backgroundColor: '#B9C7DB',
    borderRadius: 4,
  },
  text: {
    fontSize: 16,
    color: '#fff',
  },
};
