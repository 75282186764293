
import React, { useEffect, useCallback, useState } from "react";
import {
    Text,
    View,
    Image,
    ScrollView,
    Dimensions,
    Animated,
    TouchableOpacity,
    FlatList
} from "react-native";
import Header from "../components/Header";
import { connect } from "react-redux";
import * as actions from "../redux/actions"
import Loader from "../components/Loader"
import { image_url } from "../myConfig/api.json"
import CustomText from "../components/CustomText";
import i18n from "i18n-js";
import CustomHeading from "../components/Heading";
import Notfound from "../components/Notfound";

function Service(props) {
    const [listLoading, setListLoading] = useState(false)
    const [active, setActive] = useState({})
    var marginEnglish = i18n.locale=="en" ? 10 : 0;

    useEffect(() => {
        setListLoading(true)
        props.getSubCategories(props.route.params.id).then(() => setListLoading(false))
    
    }, [props.route.params?.id])

    function renderItem({ item }) {
        console.log(item.description)
    // alert(JSON.stringify({category:props.route?.params.id, sub_category:item.id}))

        return (
            <TouchableOpacity
                onPress={() =>{
                    if(props.route?.params?.forSeller){
                        props.navigation.push('vendor',{category:props.route?.params.id, sub_category:item.id,carId:props.route.params.carId})
                    }else{
                        props.navigation.push('subCategories', {...item,carId:props.route.params.carId,cat:props.route.params.id})
                    }
                }}
                key={item.id} style={{
                    backgroundColor: 'white',
                    padding: 10,
                    shadowColor: "#000",
                    shadowOffset: {
                        width: 0,
                        height: 1,
                    },
                    shadowOpacity: 0.20,
                    shadowRadius: 1.41,
                    elevation: 2,
                    borderRadius: 15,
                    marginTop: 12
                }}>
                <CustomText style={{textTransform: 'uppercase',marginLeft: marginEnglish,marginBottom : marginEnglish}} text={i18n.locale=="en"?item.title:item.ar_title}/>
                <View style={{flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flex:1,width: '30%'}}>
                        <Image
                            resizeMode="contain"
                            style={{padding: 10,width: '100%', height: 60 }}
                            source={item.image ? { uri: `${image_url}/sub-categories/${item.image}` } : require('../assets/placeholder.jpeg')}
                        />
                    </View>
                    <View style={{ width: '70%'}}>
                        <CustomText text={i18n.locale=="en"?item.description:item.ar_description} style={{color:'gray',fontSize: 12}}/>

                        {/* {item.description && item.description!='null'?<Text style={{ fontSize: 12,color:'gray' }}>{item.description}</Text>:null} */}
                    </View>
                </View>
                <View>
                    {/* <ScrollView style={{marginTop:5}} horizontal={true} showsHorizontalScrollIndicator={false}>
            {item.sub_categories.map(subItem => (
              <TouchableOpacity 
              style={{alignItems:'center',justifyContent:'center',marginHorizontal:5,backgroundColor:'#f0f0ed',paddingVertical:5,borderRadius:10,paddingHorizontal:10}}>
                  <Image
                  style={{ 
                    width: 25, 
                    height: 25,
                    borderColor:'lightgray',
                    borderWidth:1,
                    borderRadius:20,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowOpacity: 0.20,
                    shadowRadius: 1.41,
                    elevation: 2,
                  }}
                  source={subItem.image ? { uri: `${image_url}/sub-categories/${subItem.image}` } : require('../assets/placeholder.jpeg')}
                  />
                <Text style={{fontSize:10,textAlign:'center',marginTop:2}}>{subItem.title}</Text>
              </TouchableOpacity>
            ))}
          </ScrollView> */}
                </View>
            </TouchableOpacity>
        )
    }


    return (
        <View style={{ flex: 1 }}>
            <Header
                disableSearch={true}
                disableDrawer={true}
                goBack={true}
                round={true}
                disableNotifications={true}
                navigation={props} />
            <ScrollView showsVerticalScrollIndicator={false} overScrollMode="never" contentContainerStyle={{width:'85%',alignSelf:'center'}}>
                <View style={{paddingBottom: 20 }}>
                    <CustomHeading
                    style={{marginTop:10,textTransform:'capitalize'}}
                    text={i18n.locale=="en"?props.route.params?.title:props.route.params?.ar_title}
                    />
                    {listLoading ? <View style={{ height: 100, justifyContent: 'center', alignItems: 'center' }}><Loader /></View> : (
                        <FlatList
                            data={props.subCategories}
                            renderItem={renderItem}
                            ListEmptyComponent={Notfound}
                            keyExtractor={(item) => item.id}
                        />
                    )}
                </View>
            </ScrollView>
        </View>
    );
}

function mapStateToProps({ userCars, categories, subCategories }) {
    return { userCars, categories, subCategories }
}
export default connect(mapStateToProps, actions)(Service)
const styles = {
    title: {
        fontSize: 25,
        fontWeight: "bold",
        color: "white",
    },
    whiteColor: {
        color: "white",
    },
    titleBlack: {
        fontSize: 25,
        fontWeight: "bold",
        color: "black",
    },
    body: {
        backgroundColor: "#f0f0f0",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        padding: 12,
        flex: 1,
        marginTop: -10,
    },
    card: {
        flex: 1,
        height: 100,
        padding: 12,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: 12,
        shadowColor: "#00000090",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.5,
        shadowRadius: 1,
        elevation: 3,
    },
    cardTitle: {
        fontSize: 12,
        marginTop: 8,
        textAlign: "center"
    },
    cardBody: {
        fontColor: "gray",
    },
};
