import React from "react";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import { Text, View, Image, ScrollView, TouchableOpacity, Linking, FlatList, TextInput, Dimensions, KeyboardAvoidingView, Platform } from "react-native";
import Header from "../components/Header";
import { FontAwesome } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import CustomButton from "../components/CustomButton";
import { Searchbar } from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { AntDesign, Entypo } from "@expo/vector-icons";
import * as actions from "../redux/actions"
import { connect } from "react-redux";
import Loader from "../components/Loader";
import Notfound from "../components/Notfound";
import { image_url } from "../myConfig/api.json"
import { Rating, AirbnbRating } from 'react-native-ratings';
import { Toast } from "native-base";
import CustomHeading from "../components/Heading";
import i18n from "i18n-js";
import CustomText from "../components/CustomText";
import MapView from 'react-native-maps';
import ListingCard from "../components/ListingCard";
import removeTags from "../utils/removeTags";
import axios from "axios";
import queryString from 'query-string';

function ListingDetails(props) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const onChangeSearch = (query) => setSearchQuery(query);
  const [loading, setLoading] = useState(false)
  const [currentItem, setCurrentItem] = useState(0);

  const [fields, setFields] = useState({
    text: "",
    rating: 3
  })
  const [rating, setRating] = useState(3)

  const [items, setItems] = useState([]);
  const [coordinates, setCoordinates] = useState({latitude: "",longitude: ""});

  const [avgRatingss,setAvgRatings] = useState(0);

  // useEffect(() => {

  //   const fetchCoordinates = async () => {
  //       setCoordinates({
  //         latitude: parseFloat(props.listingDetails?.coordinates?.latitude),
  //         longitude: parseFloat(props.listingDetails?.coordinates?.longitude),
  //       });
  //   };
  
  //   fetchCoordinates();
  
  //   return () => {};

  //  }, [loading]);

  // get address


const [CsellerAddress,setSellerAddress] = useState('');

useEffect(()=>{
    setSellerAddress(props.listingDetails?.address);
      

},[props.listingDetails?.address]);

useEffect(() => {
  if(CsellerAddress !== ""){
   console.warn('================');
   console.warn(props.vendorDetails?.seller?.address);
   console.warn('================');
 
   const encodedAddress = CsellerAddress;
   // const encodedAddress = "https://www.google.com/maps/place/Hamriyah+Power+Station/@25.4620163,55.4790109,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f56327198701b:0x2f3fb4298e1ba3ed!8m2!3d25.4620163!4d55.4790109!16s%2Fg%2F12m929ypq";
   const apiKey = 'AIzaSyCAcgEQMvho7rnMg-cV7wLEZjJLoH50ehk';

   // console.warn(encodedAddress);
   // const { latitude, longitude } = parsedUrl.query.ll.split(',');
   // Parse the query string from the URL
   if(typeof encodedAddress === 'string'){
   const parsedUrl = queryString.parseUrl(encodedAddress);
   var address = parsedUrl.url.split("/")[5];
   
   console.warn('COOOOOOOOOOOOOOOOORINDATE');
   console.warn(address);
   console.warn('COOOOOOOOOOOOOOOOORINDATE');
   }

   const fetchCoordinates = async () => {
     try {
       const response = await fetch(
         `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`
       );
       const data = await response.json();
 
       if (data.status !== 'OK' || data.results.length === 0) {
         throw new Error('Unable to get coordinates for the address');
       }
 
       const location = data.results[0].geometry.location;
       setCoordinates({
         latitude: location.lat,
         longitude: location.lng,
       });

     } catch (error) {
       console.error(error);
     }
   };
 
   fetchCoordinates();
 
   return () => {};
  }
 }, [CsellerAddress]);
  // get address

  useEffect(() => {
    let items = Array.apply(null, Array(60)).map((v, i) => {
      //Loop to make image array to show in slider
      return {
        source: require("../assets/cars/taycan.png"),
      };
    });
    setItems(items);

  }, []);

  useEffect(() => {
    // alert(props.route?.params?.id)
    if (props.route?.params?.id) {
      setLoading(true)
      Promise.all([props.getListingDetails(props.route.params?.id), props.getReviews(props.route.params?.id)])
        .then(() => setLoading(false))
        console.warn('xxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
        console.warn(props.getReviews(props.route.params?.id));
        console.warn('xxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
    }
    
  }, [props.route])
  useEffect(()=>{
    var rating_length = props.reviews?.data?.length;
    if(rating_length){
      var all_ratings = 0;
      props.reviews?.data?.map((item, i) => {
        all_ratings = parseFloat(all_ratings + parseFloat(item.rating));
      });
      // setAvgRatings(all_ratings/rating_length);
      setAvgRatings(all_ratings/rating_length);
    }

  },[props.reviews]);

  function renderItem({ item, index }) {
    return (
      <TouchableOpacity
        // onPress={()=>props.navigation.push('ListingDetails',{carId:props.route.params.carId,catId:item.id})}
        key={item.id} style={{
          backgroundColor: 'white',
          padding: 10,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: 0.20,
          shadowRadius: 1.41,
          elevation: 2,
          borderRadius: 15,
          marginVertical: 5
        }}>
        <Text style={{ fontSize: 14, color: 'black', marginBottom: 10, textTransform: 'uppercase', marginLeft: 10, fontWeight: '700' }}>{item.title}</Text>
        <View style={{ padding: 10, flexDirection: 'row', alignItems: 'flex-start' }}>
          <View style={{ width: '30%' }}>
            <Image
              resizeMode="contain"
              style={{ width: '100%', height: 60 }}
              source={item.image ? { uri: `${image_url}/sub-categories/${item.image}` } : require('../assets/placeholder.jpeg')}
            />
          </View>
          <View style={{ width: '70%', paddingHorizontal: 5 }}>
            <Text style={{ fontSize: 10, color: 'gray' }}>{item?.description}</Text>
            {/* {item.description && item.description!='null'?<Text style={{ fontSize: 12,color:'gray' }}>{item.description}</Text>:null} */}
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  const getItemLayout = (data, index) => {
    return {
      length: Dimensions.get('screen').width,
      offset: Dimensions.get('screen').width * index,
      index,
    };
  };

  const onChangeItem = useCallback(({ viewableItems, changed }) => {
    if (viewableItems[0]?.item) {
      setCurrentItem(viewableItems[0]?.item);
    }
  }, [])
  if (loading) {
    return <Loader />
  }

  function reportThisListing(){
   var listing_id = props.listingDetails?.id;
   if(listing_id > 0){
    axios.get(`https://app.gearz.ae/api/reportListing/${listing_id}`).then(response => {
      Toast.show({title: 'Reported, You will be Notified',placement: "bottom"});
    }).catch(err =>{
    Toast.show({title: `An Error Occured ${listing_id}`,placement: "bottom"});
    })
   }
  }


  return (
    <View style={{ flex: 1 }}>
      <KeyboardAvoidingView behavior={Platform.OS == "ios" ? "padding" : "height"} style={{ flex: 1 }}>
        <Header round={true} navigation={props} goBack={true} />

        <View style={{ flex: 1, zIndex: -1 }}>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL(`tel:${props.listingDetails?.phone}`)
            }}
            style={{
              position: "absolute",
              bottom: 20,
              right: 20,
              zIndex: 10,
              backgroundColor: "#000000",
              borderRadius: 50,
              padding: 12,
              width: 48,
              justifyContent: "center",
              alignItems: "center",

            }}
          >
            <FontAwesome name="phone" size={24} color="white" />
          </TouchableOpacity>
          <ScrollView showsVerticalScrollIndicator={false} style={{ bottom: 12, zIndex: -1 }}>
            {/* <View style={styles.sliderContainer} /> */}
            <FlatList
              data={props.listingDetails?.images}
              pagingEnabled={true}
              showsHorizontalScrollIndicator={false}
              horizontal={true}
              viewabilityConfig={{
                itemVisiblePercentThreshold: 50,
              }}
              renderItem={({ item, i }) => {
                return (
                  <TouchableOpacity
                    style={{ width: Dimensions.get('screen').width }}
                    key={item.id}
                  // onPress={() => props.props.navigation.navigate("ListingDetails", { id: item.id })}
                  >
                    <Image
                      source={item.name ? { uri: `${image_url}/listings/${item.name}` } : require('../assets/placeholder.jpeg')}
                      key={i}
                      style={{ height: 210, width: Dimensions.get('screen').width }}
                      resizeMode="cover"
                    />
                  </TouchableOpacity>
                )
              }}
              onViewableItemsChanged={onChangeItem}
              getItemLayout={getItemLayout}
            />
            <View style={{ width: '85%', alignSelf: 'center' }}>
              <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 5 }}>
                {props.listingDetails?.images?.map((item, i) => (
                  <View
                    style={{
                      backgroundColor: item.id == currentItem.id ? "black" : "gray",
                      height: 10,
                      width: 10,
                      marginLeft: 6,
                      borderRadius: 5,
                    }}
                  />
                ))}
              </View>
              <View>
                {/* <View
        style={{
          borderColor: "black",
          borderRadius: 20,
          borderWidth: 1,
          paddingLeft: 12,
          paddingBottom: 6,
          paddingTop: 6,
          paddingRight: 12,
          width: 60,
        }}
      >
        <Text>{props?.listingDetails?.start_year}</Text>
      </View> */}
                {props.route?.params?.sellerList ? <CustomHeading text={i18n.locale == "en" ? props.listingDetails?.garage_name : props.listingDetails?.ar_garage_name} /> : <CustomHeading text={i18n.locale == "en" ? props.listingDetails?.title : props.listingDetails?.ar_title} />}
                {false ? (
                  <>
                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: i18n.locale == "en" ? 'flex-start' : "flex-end" }}>
                      <Entypo name="star" size={16} color="black" />
                      <CustomText style={{ ...styles.reviewTitle, marginLeft: 5, color: 'black' }}/>
                      <Text style={{ ...styles.reviewTitle, marginLeft: 5, color: 'black' }}>{}</Text>
                    </View>
                  </>
                ) : (
                  <>
                    <CustomText
                      text={`${props.listingDetails?.price} AED`}
                    />
                    <CustomText
                      style={{ color: 'gray', fontSize: 11 }}
                      text={i18n.locale == "en" ? props.listingDetails?.garage_name : props.listingDetails?.ar_garage_name}
                    />
                  </>
                )}
                <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT1')} />
                <View style={{ minHeight: 80, borderRadius: 20, backgroundColor: 'white', color: 'gray', padding: 10, borderWidth: 1, borderColor: 'lightgray', }}>
                  <CustomText style={{ fontSize: 11 }} text={i18n.locale == "en" ?removeTags(props.listingDetails?.description):removeTags(props.listingDetails?.ar_description)} />
                </View>
                <CustomButton
                  text={(props.route?.params?.sellerList) ? i18n.t('C19') : i18n.t('VIEW_LIST_TEXT2')}
                  textColor="black"
                  bgColor="white"
                  // onPress={() => props.navigation.push('vendor')}
                  onPress={() => props.navigation.push('vendorDetails', {id:props.listingDetails?.seller?.id})}
                  // onPress={() => props.navigation.navigate("Search", { id: props.listingDetails?.seller?.id, second: true })}
                  style={{ marginTop: 15, borderColor: 'lightgray', borderWidth: 1, borderRadius: 20 }}
                />
                <View>
                  <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT6')} />
                  <View style={{ height: 200, borderRadius: 20, borderColor: 'lightgray', borderWidth: 1, overflow: 'hidden' }}>
                  {coordinates.latitude !== "" ?                            
                  <MapView
  initialRegion={{
    latitude: Number(props.vendorDetails?.coordinates?.latitude ?? '25.2048'),
    longitude: Number(props.vendorDetails?.coordinates?.longitude ?? '55.2708'),
    latitudeDelta: 0.44,
    longitudeDelta: 0.44,
  }}
  region={{
    latitude: coordinates?.latitude ?? Number(props.vendorDetails?.coordinates?.latitude),
    longitude: coordinates?.longitude ?? Number(props.vendorDetails?.coordinates?.longitude),
    latitudeDelta: 0.44,
    longitudeDelta: 0.44,
  }}
  style={{ height: 200 }}
>
  <MapView.Marker
    coordinate={{
      latitude: coordinates?.latitude ?? Number(props.vendorDetails?.coordinates?.latitude),
      longitude: coordinates?.longitude ?? Number(props.vendorDetails?.coordinates?.longitude),
    }}
  />
</MapView> 
: <Text>Loading..</Text>}
                  </View>
                  <CustomButton
                    text={i18n.t('G3')}
                    textColor="black"
                    bgColor="white"
                    onPress={() => Linking.openURL(props.listingDetails?.address)}
                    style={{ marginTop: 15, borderColor: 'lightgray', borderWidth: 1, borderRadius: 20 }}
                  />
                  <CustomButton
              text={i18n.t('REPORT_CUSTOM_TEXT')}
                    textColor="white"
                    bgColor="black"
                    onPress={() => reportThisListing()}
                    style={{ marginTop: 15,marginBottom: 15, borderColor: 'lightgray', borderWidth: 1, borderRadius: 20 }}
                  />
                  <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT3')} />
                  {/* <Text style={styles.sectionTitles}>User Reviews</Text> */}
                  <View>
                    {(props.reviews?.data?.length) ? (
                      <>
                        {props.reviews?.data?.map((item, i) => {
                          return (
                            <View key={item.id} style={{ ...styles.reviewCards, marginTop: i == 0 ? 0 : 12, flexDirection: 'row', justifyContent: 'space-between' }}>
                              <CustomText
                              style={{ ...styles.reviewDesc, width: '90%' }}
                              text={item.text}
                              />
                              {/* <Text style={{ ...styles.reviewDesc, width: '90%' }}>
                                {item.text}
                              </Text> */}
                              <View style={{ flexDirection: "row", alignItems: "center" }}>
                                <Entypo name="star" size={16} color="black" />
                                <CustomText
                                style={styles.reviewTitle}
                                text={item.rating}
                                />
                                {/* <Text style={styles.reviewTitle}>{item.rating}</Text> */}
                              </View>
                            </View>
                          )
                        })}
                      </>
                    ) : <Notfound />}
                  </View>
                </View>
                {!(props.route?.params?.sellerList) &&
                  <View style={{ marginTop: 24 }}>
                    <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT4')} />

                    {/* <Text style={styles.sectionTitles}>Add Review</Text> */}
                    <View style={{ marginBottom: 100, backgroundColor: 'white', borderColor: 'lightgray', borderWidth: 1, borderRadius: 20, padding: 10, alignItems: 'center' }}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <View style={{ width: '85%', alignSelf: 'center', borderColor: 'lightgray', borderWidth: 1, backgroundColor: 'white', height: 40, justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10, borderRadius: 20 }}>
                          <TextInput
                            onChangeText={v => setFields({ ...fields, text: v })}
                            value={fields.text}
                            style={{ width: '100%',fontFamily:i18n.locale=="en"?"Poppins-Regular" : "GE-SS-Unique-Light" }}
                            placeholder="add review"
                          />
                        </View>
                      </View>
                      <View style={{ width: '85%', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Rating
                          showRating
                          onFinishRating={rating => setRating(rating)}
                          imageSize={30}
                          startingValue={avgRatingss}
                          ratingColor="black"
                          ot
                          fractions={true}
                          type="custom"
                          ratingTextColor="black"
                          style={{ paddingVertical: 10 }}
                        />
                        <TouchableOpacity
                          onPress={() => {
                            if (fields.rating && fields.text) {
                              props.addReview({ ...fields, rating, listing_id: props.route?.params?.id }).then(() => {
                                props.getReviews(props.route.params?.id)
                                Toast.show({ title: 'Successfully added' })
                              })
                            } else {
                              Toast.show({ title: "review and rating both are required", tintColor: 'red.100' })
                            }
                          }}
                          style={{ backgroundColor: '#f0f0f0', marginTop: 15, height: 30, justifyContent: 'center', alignItems: 'center', width: 80, borderRadius: 20, borderColor: 'lightgray' }}>
                          <CustomText style={{ color: 'black' }} text={i18n.t('VIEW_LIST_BTN')}></CustomText>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                }
                {props.route?.params?.sellerList && (
                  <>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 10 }}>
                      <CustomHeading style={{ marginVertical: 5 }} text={i18n.t('VIEW_LIST_TEXT7')} />
                      <TouchableOpacity
                        onPress={() => props.navigation.navigate("Search", { id: props.listingDetails?.seller?.id })}
                        style={{
                          height: 40,
                          width: 40,
                          borderRadius: 20,
                          backgroundColor: 'white',
                          marginHorizontal: 5,
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderColor: 'lightgray',
                          borderWidth: 1,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.23,
                          shadowRadius: 2.62,

                          elevation: 4,
                        }}>
                        <FontAwesome name="filter" size={24} color="black" />
                      </TouchableOpacity>
                    </View>
                  </>
                )}
              </View>
            </View>
            {props.route?.params?.sellerList && (
              <>
                <FlatList
                  horizontal={true}
                  data={props.vendorListing?.data}
                  showsHorizontalScrollIndicator={false}
                  renderItem={({ item, index }) => <ListingCard sellerList={true} item={item} props={props} style={{ marginLeft: index == 0 ? 42 : 10 }} />}
                  keyExtractor={(item) => item.id.toString()}
                  ListEmptyComponent={Notfound}
                />
              </>
            )}
          </ScrollView>
        </View>
      </KeyboardAvoidingView>
    </View>
  );
}

function mapStateToProps({ listingDetails, reviews, vendorListing }) {
  return { listingDetails, reviews, vendorListing }
}

export default connect(mapStateToProps, actions)(ListingDetails)

const styles = {
  listingCard: {
    height: 300,
    padding: 12,
    backgroundColor: "white",
    borderRadius: 12,
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
  },
  sliderContainer: {
    height: 170,
    backgroundColor: "#00000000",
  },
  listingTitle: {
    fontSize: 18,
    marginTop: 12,
  },
  listingOffer: {
    fontSize: 25,
    marginTop: 6,
  },
  listingDesc: {
    color: "gray",
    marginTop: 12,
  },
  sectionTitles: {
    fontSize: 22,
    fontWeight: "bold",
  },
  specficationCards: {
    padding: 16,
    borderColor: "#c0c0c0",
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: "white",
    shadowColor: "#00000090",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 1,
    elevation: 3,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    marginLeft: 6,
    marginRight: 6,
  },
  reviewCards: {
    padding: 10,
    borderRadius: 20,
    borderColor: "lightgray",
    borderWidth: 1,
    backgroundColor: "white"
  },
  reviewTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: 'gray'
    // marginLeft: 8,
  },
  reviewDesc: {
    fontSize: 14,
    color: 'gray',
  },
};
